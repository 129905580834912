import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Button, DialogActions, DialogContent, FormControlLabel, Grid, Radio} from '@mui/material';
import RadioGroup from "@mui/material/RadioGroup";
import {useAuth0} from "@auth0/auth0-react";
import {useSnackbar} from "notistack";
import {DropDownModel} from 'src/resources/DropDownModel';
import {t} from 'i18next';
import {useNavigate} from "react-router-dom"
import {MATKAKULU_API} from "../../../routes/routes-api";
import {requestHelpers} from "../../../utils/requestHelper";
import useSessionUser from "../../../resources/hooks/useSessionUser";
import {LoadingButton} from "../../../components/LoadingButton";
import {MATKAKULU_WEB} from "../../../routes/routes-web";

interface ReturnModel {
    tenants: DropDownModel[]
    defaultTenantId: string
}

export function ChangeTenantDialog({open, onClose}: { open: boolean, onClose: () => void }) {
    const [selectedValue, setSelectedValue] = useState<string | undefined>();
    const {getAccessTokenSilently} = useAuth0()
    const radioGroupRef = useRef(null);
    const [dataFetched, setDataFetched] = useState(false)
    const [dropDownModel, setDropDownModel] = useState<DropDownModel[]>([]);
    const {enqueueSnackbar} = useSnackbar()
    const {clearSessionUser} = useSessionUser()
    const navigate = useNavigate()

    useEffect(() => {
        if (dataFetched) {
            return;
        }

        setDataFetched(true);

        const fetchTenantsData = async () => {
            const accessToken = await getAccessTokenSilently();
            const response = await fetch(
                MATKAKULU_API.user.getUserTenants,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.ok) {
                const data = await response.json() as ReturnModel;
                setDropDownModel(data.tenants);
                setSelectedValue(data.defaultTenantId);
            }
        };
        // noinspection JSIgnoredPromiseFromCall
        fetchTenantsData();
    }, [getAccessTokenSilently, dataFetched]);

    const handleSubmit = async () => {

        const accessToken = await getAccessTokenSilently()

        const response = await fetch(MATKAKULU_API.user.switchTenant, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({tenantId: selectedValue}),
        })
        if (response.bodyUsed) {
            await requestHelpers().handleResponse(response, enqueueSnackbar);
        }

        onClose();
        clearSessionUser();
        navigate(MATKAKULU_WEB.frontPage);
    }

    // TODO KK: kato dialogien paddingit, tulee dialog contentista

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Vaihda asiakastiliä</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RadioGroup
                            ref={radioGroupRef}
                            value={selectedValue || null}
                            onChange={(event) => setSelectedValue(event.target.value)}
                        >
                            {dropDownModel.map((dropdown: any) => (
                                <FormControlLabel
                                    value={dropdown.value || null}
                                    key={dropdown.value}
                                    control={<Radio />}
                                    label={dropdown.name}
                                />
                            ))}
                        </RadioGroup>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton variant="contained" onClick={handleSubmit}>
                    {t("general.save")}
                </LoadingButton>
                <Button variant="outlined" onClick={onClose}>
                    {t("general.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}