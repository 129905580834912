import {Box, useTheme} from "@mui/material";
import {NavLink} from "react-router-dom";
import FlexBox from "../../components/flexbox/FlexBox";
import {H2, Paragraph} from "../../components/Typography";

export function PermissionError() {
    const theme = useTheme();
    return (
        <FlexBox p={4} height="100%" alignItems="center" flexDirection="column" justifyContent="center">
            <Box maxWidth={350}>
                <img src="/static/illustration/error-page.svg" width="100%" alt="" />
            </Box>
            <H2 fontWeight={700} color="primary.main" mt={3}>
                Ooops... There must be something wrong with permissions!
            </H2>
            <Paragraph color="text.disabled" fontWeight="500" fontStyle="italic">
                Check PermissionGuard&apos;s permission list
            </Paragraph>

            <NavLink
                to="/"
                style={{
                    display: 'block',
                    marginTop: '1.5rem',
                    fontWeight: 600,
                    textDecoration: 'underline',
                    color: theme.palette.primary.main,
                }}
            >
                Back to Dashboard
            </NavLink>
        </FlexBox>
    );
}
