import {Box, styled, Theme, useMediaQuery, useTheme} from "@mui/material";
import FlexBetween from "src/components/flexbox/FlexBetween";
import FlexBox from "src/components/flexbox/FlexBox";
import Scrollbar from "src/components/ScrollBar";
import {FC, useState} from "react";
// import ArrowLeftToLine from "src/icons/duotone/ArrowLeftToLine";
import {useNavigate} from "react-router-dom";
import MultiLevelMenu from "./MultiLevelMenu";
import MobileSidebar from "./MobileSidebar";

const TOP_HEADER_AREA = 93.27;

const SidebarWrapper = styled(Box)<{ compact: number }>(({theme, compact}) => ({
    height: "100vh",
    position: "fixed",
    width: compact ? 86 : 280,
    transition: "all .2s ease",
    zIndex: theme.zIndex.drawer,
    backgroundColor: theme.palette.background.paper,
    "&:hover": compact && {width: 280},
}));

const NavWrapper = styled(Box)(() => ({
    paddingLeft: 16,
    paddingRight: 16,
    height: "100%",
}));

const StyledLogo = styled(Box)(() => ({
    paddingLeft: 8,
    fontWeight: 700,
    fontSize: 20,
}));

// const StyledArrow = styled(ArrowLeftToLine)(() => ({
// 	display: "block",
// }));
//
// const StyledIconButton = styled(IconButton)(({ theme }) => ({
// 	"&:hover": { backgroundColor: theme.palette.action.hover },
// }));

// -----------------------------------------------------------------------------
type DashboardSidebarProps = {
    sidebarCompact: boolean;
    showMobileSideBar: boolean;
    toggleCompactSideBar: () => void;
    toggleMobileSideBar: () => void;
};
// -----------------------------------------------------------------------------

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
    const {sidebarCompact, showMobileSideBar, toggleMobileSideBar, toggleCompactSideBar} = props;

    const navigate = useNavigate();

    const [onHover, setOnHover] = useState(false);
    const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

    // Activate compact when toggle button clicked and not on hover state
    const COMPACT = sidebarCompact && !onHover ? 1 : 0;

    const theme = useTheme();

    const logoUrl = theme.palette.mode === "dark" ? "/static/logo/logo-transparent-dark.png" : "/static/logo/logo-transparent-white.png";

    //   IF MOBILE
    if (downLg) {
        return (
            <MobileSidebar
                sidebarCompact={!!COMPACT}
                showMobileSideBar={!!showMobileSideBar}
                toggleMobileSideBar={toggleMobileSideBar}
            />
        );
    }

    return (
        <SidebarWrapper
            compact={sidebarCompact ? 1 : 0}
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => sidebarCompact && setOnHover(false)}
        >
            <Box pt={2}
                 ml={2}
                 mb={0}
                 pr={2}
                 sx={{cursor: "pointer"}}
                 onClick={() => {
                     toggleMobileSideBar()
                     navigate("/")
                 }}
            >
                <img src={logoUrl} alt="logo" style={{width: "400px", maxWidth:"85%", height: "auto"}} />
            </Box>

            {/* NAVIGATION ITEMS */}
            <Scrollbar
                autoHide
                clickOnTrack={false}
                sx={{overflowX: "hidden", maxHeight: `calc(100vh - ${TOP_HEADER_AREA}px)`}}
            >
                <NavWrapper>
                    <MultiLevelMenu sidebarCompact={!!COMPACT} />
                </NavWrapper>
            </Scrollbar>
        </SidebarWrapper>
    );
};

export default DashboardSidebar;
