import Login from "src/pages/authentication/login-v2";
import React, {ReactNode, useState} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import LoadingScreen from "src/components/LoadingScreen";
import useSessionUser from "../../resources/hooks/useSessionUser";
import {TenantSelector} from "../../pages/authentication/tenant-selector";

interface AuthGuardProps {
    children: ReactNode;
}

const AuthGuard = ({children}: AuthGuardProps) => {
    const {isAuthenticated, isLoading} = useAuth0();
    const {pathname} = useLocation();
    const {sessionUser, isLoading: isLoadingSessionUser} = useSessionUser();

    const [requestedLocation, setRequestedLocation] = useState<string | null>();
    
    if (isLoading) {
        return <LoadingScreen />;
    }

    if (!isLoading && !isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }

        return <Login />;
    }

    if (isLoadingSessionUser) {
        return <LoadingScreen />;
    }

    if (sessionUser.redirectToTenantSelector) {
        return <TenantSelector />;
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }

    return <>{children}</>;
};

export default AuthGuard;
