import React, {FC} from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import useSettings from "src/hooks/useSettings";
import {fiFI} from "@mui/material/locale";
import {setLocale} from "yup";
import {t} from "i18next";
import {createCustomTheme} from "./theme";
import "./i18n";
import {authRoutes, routes} from "./routes/routes";
import {PermissionGuard} from "./page-sections/authentication/PermissionGuard";
import {PrintStyles} from "./PrintStyles";

const App: FC = () => {
    if (process.env.NODE_ENV === "development") {
        authRoutes.forEach((route: any) => {
            if (!route.path.startsWith("/") && route.path.endsWith("/")) {
                throw new Error(`All routes must not end in slash. ${route.path}`);
            }
        })
    }

    const router = createBrowserRouter(routes());
    const {settings} = useSettings();

    let theme = createCustomTheme({
        theme: settings.theme,
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
    });

    setLocale({
        mixed: {
            required: () => t("general.validation.required_field"),
        },
        string: {
            email: () => t("general.validation.invalid_email"),
            min: (params) => t("general.validation.min_length").replace("{0}", `${params.min}`),
            max: (params) => t("general.validation.max_length").replace("{0}", `${params.max}`),
        },
        number: {
            min: (params) => t("general.validation.min_value").replace("{0}", `${params.min}`),
            max: (params) => t("general.validation.max_value").replace("{0}", `${params.max}`),
        },
    });

    theme = createTheme(theme, fiFI);
    
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <PrintStyles />
                <PermissionValidator>
                    <RouterProvider router={router} />
                </PermissionValidator>
            </ThemeProvider>
        </StyledEngineProvider>
    ); // TODO KK: Kato indexin meta tiedot, mitkä pakollisia
};

export default App;

function PermissionValidator({children}: { children: any }) {
    if (process.env.NODE_ENV !== "development") {
        return <>{children}</>;
    }

    authRoutes.forEach((route: any) => {
        if (route.element.type !== PermissionGuard && route.path !== "/") {
            throw new Error(`All routes must be wrapped in PermissionGuard. ${route.path}`);
        }
    })

    return (
        <>
            {children}
        </>
    )
}