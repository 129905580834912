export function PrintStyles() {
    const css = `
        @media print {        
            * {
                font-size: 10px;
            }
            
            #dashboard-header {
                display: none;
            }
            
            #layout-body-wrapper {
                padding-left: 0;
                padding-right: 0;
                margin-left: 0;
                margin-right: 0;
            }
            
            #monthly-report-wrapper {
                padding-top: 0;
            }
            
            #monthly-report-header {
                display: none;
            }
            
            #monthly-report-filters {
                display: none;
            }
            
            #monthly-report {
                box-shadow: none;
                padding: 0;
            }
            
            #monthly-report h1 {
                font-size: 13px;
            }
            
            #monthly-report h3 {
                font-size: 12px;
            }
            
            #monthly-report table thead th {
                font-size: 10px;
                min-width: initial;
                padding: 5px;
                padding-left: 0;
            }
            
            #monthly-report table tbody td {
                font-size: 10px;
                padding: 7px;
                padding-left: 0;
            }
        }
    `;

    return (
        <style>
            {css}
        </style>
    )
}