import {SvgIcon, SvgIconProps} from "@mui/material";

const Instagram = (props: SvgIconProps) =>
    (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M16,3H8A5,5,0,0,0,3,8v8a5,5,0,0,0,5,5h8a5,5,0,0,0,5-5V8A5,5,0,0,0,16,3Zm3.25,13A3.26,3.26,0,0,1,16,19.25H8A3.26,3.26,0,0,1,4.75,16V8A3.26,3.26,0,0,1,8,4.75h8A3.26,3.26,0,0,1,19.25,8Zm-1.5-8.75a1,1,0,1,1-1-1A1,1,0,0,1,17.75,7.25ZM12,7.5A4.5,4.5,0,1,0,16.5,12,4.49,4.49,0,0,0,12,7.5Zm0,7.25A2.75,2.75,0,1,1,14.75,12,2.75,2.75,0,0,1,12,14.75Z"
            />
        </SvgIcon>
    );

export default Instagram;
