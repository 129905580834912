import {Badge, Box, ButtonBase, Divider, styled} from '@mui/material';
import AppAvatar from "src/components/avatars/AppAvatar";
import FlexBox from "src/components/flexbox/FlexBox";
import {H6, Small, Tiny} from "src/components/Typography";
import {FC, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import PopoverLayout from './PopoverLayout';
import useSessionUser from "../../../resources/hooks/useSessionUser";
import {ChangeTenantDialog} from './ChangeTenantDialog';


// styled components
const StyledButtonBase = styled(ButtonBase)(({theme}) => ({
    padding: 5,
    marginLeft: 4,
    borderRadius: 30,
    border: `1px solid ${theme.palette.divider}`,
    '&:hover': {backgroundColor: theme.palette.action.hover},
}));

const StyledSmall = styled(Small)(({theme}) => ({
    display: 'block',
    cursor: 'pointer',
    padding: '5px 1rem',
    '&:hover': {backgroundColor: theme.palette.action.hover},
}));

const ProfilePopover: FC = () => {
    const anchorRef = useRef(null);
    const navigate = useNavigate();
    const {logout} = useAuth0();
    const [open, setOpen] = useState(false);
    const {sessionUser, clearSessionUser} = useSessionUser();

    const handleMenuItem = (path: string) => {
        navigate(path);
        setOpen(false);
    };

    const [openDialog, setOpenDialog] = useState(false);

    return (
        <>
            <StyledButtonBase disableRipple ref={anchorRef} onClick={() => setOpen(true)}>
                <Badge
                    overlap="circular"
                    variant="dot"
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    sx={{
                        alignItems: 'center',
                        '& .MuiBadge-badge': {
                            width: 11,
                            height: 11,
                            right: '4%',
                            borderRadius: '50%',
                            border: '2px solid #fff',
                            backgroundColor: 'success.main',
                        },
                    }}
                >
                    <AppAvatar
                        src="/static/avatar/001-man.svg" // TODO KK: USerin avatari
                        sx={{width: 40, height: 40}}
                    />
                </Badge>
            </StyledButtonBase>

            <PopoverLayout
                hiddenViewButton
                maxWidth={230}
                minWidth={200}
                popoverOpen={open}
                anchorRef={anchorRef}
                popoverClose={() => setOpen(false)}
                title={
                    <FlexBox alignItems="center" gap={1}>
                        <AppAvatar
                            src="/static/avatar/001-man.svg" // TODO KK: USerin avatari
                            sx={{width: 35, height: 35}}
                        />

                        <Box>
                            <H6>{sessionUser?.userFirstname} {sessionUser?.userLastname}</H6>
                            <Tiny display="block" fontWeight={500} color="text.disabled">
                                {sessionUser?.userEmail}
                            </Tiny>
                        </Box>
                    </FlexBox>
                }
            >
                <Box pt={1}>
                    <StyledSmall onClick={() => handleMenuItem('/profile-page')}>
                        Profiili
                    </StyledSmall>

                    {sessionUser.showTenantSwitchButton &&
                        <>
                            <StyledSmall
                                onClick={() =>
                                    setOpenDialog(true)
                                }
                            >
                                Vaihda asiakastiliä
                            </StyledSmall>

                            {openDialog &&
                                <ChangeTenantDialog
                                    open
                                    onClose={() => setOpenDialog(false)}
                                />
                            }
                        </>
                    }

                    {process.env.NODE_ENV === "development" &&
                        <StyledSmall
                            onClick={() => {
                                clearSessionUser();
                                window.location.reload();
                            }}
                        >
                            Tyhjennä SessionUser
                        </StyledSmall>
                    }

                    <Divider sx={{my: 1}} />

                    <StyledSmall
                        onClick={() => {
                            logout({logoutParams: {returnTo: window.location.origin}})
                            navigate('/logout');
                        }}
                    >
                        Kirjaudu ulos
                    </StyledSmall>
                </Box>
            </PopoverLayout>
        </>
    );
};

export default ProfilePopover;
