import LayoutBodyWrapper from "src/layouts//layout-parts/LayoutBodyWrapper";
import {CSSProperties, FC, ReactNode, useState} from "react";
import {Outlet} from "react-router-dom";
import DashboardHeader from "../layout-parts/DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";

// --------------------------------------------
type DashboardLayoutProps = {
    children?: ReactNode;
};
// --------------------------------------------

const DashboardLayout: FC<DashboardLayoutProps> = ({children}) => {
    const [sidebarCompact, setSidebarCompact] = useState(false);
    const [showMobileSideBar, setShowMobileSideBar] = useState(false);

    const toggleCompactSideBar = () => setSidebarCompact(!sidebarCompact);
    const toggleMobileSideBar = () =>
        setShowMobileSideBar((state) => !state);

    // dashboard body wrapper custom style
    const customStyle: CSSProperties = {
        width: `calc(100% - ${sidebarCompact ? "86px" : "280px"})`,
        marginLeft: sidebarCompact ? "86px" : "280px",
        paddingBottom: "30px"
    };

    return (
        <>
            <DashboardSidebar
                sidebarCompact={sidebarCompact}
                showMobileSideBar={showMobileSideBar}
                toggleCompactSideBar={() => toggleCompactSideBar()}
                toggleMobileSideBar={toggleMobileSideBar}
            />

            <LayoutBodyWrapper sx={customStyle}>
                <DashboardHeader
                    toggleCompactSideBar={toggleCompactSideBar}
                    toggleMobileSideBar={toggleMobileSideBar}
                />

                {children || <Outlet />}
            </LayoutBodyWrapper>
        </>
    );
};

export default DashboardLayout;
