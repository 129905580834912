import {useState} from "react";
import {ButtonProps} from "@mui/material/Button";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";

interface LoadingButtonProps extends ButtonProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>
}

export function LoadingButton(props: LoadingButtonProps) {
    const {onClick, children, sx, variant} = props;
    const [loading, setLoading] = useState(false);

    async function onClickHandler(event: any) {
        if (onClick == null) return;

        setLoading(true);
        try {
            await onClick(event);            
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <MuiLoadingButton loading={loading} {...props} onClick={onClickHandler}>
            {children}
        </MuiLoadingButton>
    )
}