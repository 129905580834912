import {Box, Divider, IconButton, Popover, Tooltip} from "@mui/material";
import {H4} from "src/components/Typography";
import React, {FC} from "react";
import DoneAllIcon from '@mui/icons-material/DoneAll';


// component props interface
interface NotificationPopoverLayoutProps {
    title: string | JSX.Element;
    popoverOpen: boolean;
    popoverClose: () => void;
    children: React.ReactNode;
    anchorRef: React.MutableRefObject<null>;
    minWidth?: number | string;
    maxWidth?: number | string;
    checkAllNotificationsAsSeen: () => void;
    newNotificationCount: number | undefined;
}

export const NotificationPopoverLayout: FC<NotificationPopoverLayoutProps> = (props) => {
    const {
        children,
        popoverClose,
        popoverOpen,
        anchorRef,
        title,
        minWidth,
        maxWidth,
        checkAllNotificationsAsSeen,
        newNotificationCount
    } = props;
    return (
        <Popover
            open={popoverOpen}
            onClose={popoverClose}
            anchorEl={anchorRef.current}
            anchorOrigin={{horizontal: "center", vertical: "bottom"}}
            PaperProps={{
                sx: {
                    minWidth: minWidth || 250,
                    maxWidth: maxWidth || 375,
                    width: "100%",
                },
            }}
        >
            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                <H4 fontWeight="700" p={2}>
                    {title || "Notifications"}
                </H4>
                <Box sx={{pr: 2}}>
                    <IconButton disabled={newNotificationCount === 0} onClick={() => checkAllNotificationsAsSeen()}
                                color="success"
                    >
                        <Tooltip title="Merkitse ilmoitukset luetuksi">
                            <DoneAllIcon />
                        </Tooltip>
                    </IconButton>
                </Box>
            </Box>
            <Divider />
            {children}
        </Popover>
    );
};