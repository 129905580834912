export class SessionUserModel {
    sessionUser: SessionUser

    isLoading: boolean

    constructor(sessionUser: SessionUser, isLoading: boolean) {
        this.sessionUser = sessionUser
        this.isLoading = isLoading
        this.clearSessionUser = this.clearSessionUser.bind(this)
    }
 
    // eslint-disable-next-line class-methods-use-this
    clearSessionUser() {
        localStorage.removeItem("SESSION_USER")
    }
}

export class SessionUser {
    userFirstname: string

    userLastname: string

    userEmail: string

    validUntil: string | undefined

    permissions: string[]

    redirectToTenantSelector: boolean

    showTenantSwitchButton: boolean
    
    userId: string

    constructor(userFirstname: string, userLastname: string, userEmail: string, validUntil: Date | undefined, permissions: string[], redirectToTenantSelector: boolean, showTenantSwitchButton: boolean, userId: string) {
        this.userFirstname = userFirstname
        this.userLastname = userLastname
        this.userEmail = userEmail
        this.validUntil = validUntil?.toUTCString()
        this.permissions = permissions
        this.redirectToTenantSelector = redirectToTenantSelector
        this.showTenantSwitchButton = showTenantSwitchButton
        this.userId = userId
    }

    static Create() {
        return new SessionUser("", "", "", undefined, [], false, false, "")
    }

    HasPermission(permission: string) {
        return this.permissions.includes(permission);
    }
}
