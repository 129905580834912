export const MATKAKULU_WEB = {

    admin: {
        contract: {
            list: `/admin/contracts`,
            edit: (contractId: string) => `/admin/contracts/${contractId}`,
        },

        invoice: {
            list: `/admin/invoices`,
            view: (invoiceId: string) => `/admin/invoices/${invoiceId}`,
        },

        kilometerAllowance:
            {
                list: `/admin/kilometer-allowances`,
                edit: (kilometerAllowancesId: string) => `/admin/kilometer-allowances/${kilometerAllowancesId}`,
                create: `/admin/kilometer-allowances/create`,
            },

        mealCompensationAllowance:
            {
                list: `/admin/meal-compensation-allowances`,
                edit: (mealCompensationAllowanceId: string) => `/admin/meal-compensation-allowances/${mealCompensationAllowanceId}`,
                create: `/admin/meal-compensation-allowances/create`,
            },
        
        nightTravelAllowance:
            {
                list: `/admin/night-travel-allowances`,
                edit: (nightTravelAllowanceId: string) => `/admin/night-travel-allowances/${nightTravelAllowanceId}`,
                create: `/admin/night-travel-allowances/create`,
            },

        paymentTerm:
            {
                list: `/admin/payment-terms`,
                create: `/admin/payment-terms/create`,
                edit: (paymentTermsId: string) => `/admin/payment-terms/${paymentTermsId}`,
            },

        perDiemAllowance:
            {
                list: `/admin/per-diem-allowances`,
                edit: (perDiemAllowanceId: string) => `/admin/per-diem-allowances/${perDiemAllowanceId}`,
            },

        product:
            {
                list: `/admin/products`,
                create: `/admin/products/create`,
                edit: (productsId: string) => `/admin/products/${productsId}`,
            },

        provisions:
            {
                list: `/admin/provisions`,
            },
        
        role:
            {
                list: `/admin/roles`,
                create: `/admin/roles/create`,
                edit: (roleId: string) => `/admin/roles/${roleId}`,
            },

        seller:
            {
                list: `/admin/sellers`,
                create: `/admin/sellers/create`,
                edit: (sellerId: string) => `/admin/sellers/${sellerId}`,
            },

        taxRate:
            {
                list: `/admin/tax-rates`,
                create: `/admin/tax-rates/create`,
                edit: (taxRatesId: string) => `/admin/tax-rates/${taxRatesId}`,
                
                taxRateRow: {
                    create: (taxRatesId: string) => `/admin/tax-rates/${taxRatesId}/tax-rate-row/`,
                    edit: (taxRatesId: string, taxRateRowId: string) => `/admin/tax-rates/${taxRatesId}/tax-rate-row/${taxRateRowId}`,
                }
            },

        tenant:
            {
                list: `/admin/tenants`,
                edit: (tenantId: string) => `/admin/tenants/${tenantId}`,

                productPricing:
                    {
                        list: (tenantId: string) => `/admin/tenants/${tenantId}/product-pricing`,
                        create: (tenantId: string) => `/admin/tenants/${tenantId}/product-pricing/create`,
                        edit: (tenantId: string, productPricingId: string) => `/admin/tenants/${tenantId}/product-pricing/${productPricingId}`,
                    },

                signupInvitation: {
                    list: `/admin/tenants/signup-invitation`,
                    create: `/admin/tenants/signup-invitation/create`,
                }
            }
    },
    
    tenantSignUpSuccessful:`/register/customer/success`,
    
    userSignUpSuccessful:`/register/user/success`,
    
    frontPage: `/`,

    user:
        {
            create: `/users/create`,
            list: `/users`,
            edit: (userId: string) => `/users/${userId}`,
        },

    ride:
        {
            list: `/rides`,
            create: `/rides/create`,
            edit: (ridesId: string) => `/rides/${ridesId}`,
        },
    
    rideTemplate:
        {
            list: `/ride-templates`,
            create: `/ride-templates/create`,
            edit: (templateId: string) => `/ride-templates/${templateId}`,
        },

    report:
        {
            list: `/report`,
            monthly: `/report/monthly`,
            travelInvoiceReport: `/travel-invoice-report`,
        },

    vehicle:
        {
            list: `/vehicles`,
            create: `/vehicles/create`,
            edit: (vehiclesId: string) => `/vehicles/${vehiclesId}`,
        },

    attachment:
        {
            list: `/attachments`,
            create: `/attachments/create`,
            edit: (attachmentsId: string) => `/attachments/${attachmentsId}`,
        },

    recurringRide:
        {
            list: `/rides/recurrence`,
            edit: (recurringRidesId: string) => `/rides/recurrence/${recurringRidesId}`,
        },

    travelInvoice:
        {
            list: `/travel-invoices`,
            create: `/travel-invoices`,
            edit: (travelInvoiceId: string) => `/travel-invoice/${travelInvoiceId}`, // TODO KK: urli kuntoon
        },

    addressBook:
        {
            list: `/address-book`,
            create: `/address-book/create`,
            edit: (addressBookId: string) => `/address-book/${addressBookId}`,
        },

    accountDimensions:
        {
            list: `/account-dimensions`,
            create: `/account-dimensions/create`,
            edit: (accountDimensionId: string) => `/account-dimensions/${accountDimensionId}`,
        },
}
