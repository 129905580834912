import {SvgIcon, SvgIconProps} from "@mui/material";

const CsvFileIcon = (props: SvgIconProps) =>
    (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M13.9763 2.12046H12.6269V0.771092C12.6269 0.346968 12.2799 0 11.856 0H2.02448C1.60039 0 1.25342 0.346968 1.25342 0.771092V13.1084C1.25342 13.5325 1.60039 13.8795 2.02451 13.8795H3.37391V15.2289C3.37391 15.653 3.72073 16 4.14501 16H10.699C11.1229 16 11.7228 15.7621 12.0315 15.4716L14.1855 13.4441C14.4945 13.1536 14.747 12.5687 14.747 12.1446L14.7474 2.89162C14.7474 2.46746 14.4004 2.12046 13.9763 2.12046ZM3.37385 2.89159V13.3012H2.02448C1.92001 13.3012 1.83173 13.2129 1.83173 13.1084V0.771092C1.83173 0.666624 1.92001 0.578343 2.02448 0.578343H11.856C11.9603 0.578343 12.0487 0.666624 12.0487 0.771092V2.1205H4.14494C3.72066 2.1205 3.37385 2.46746 3.37385 2.89159ZM13.7891 13.023L11.6351 15.0504C11.591 15.0919 11.5335 15.1325 11.4701 15.1713V13.3976C11.4701 13.2651 11.5235 13.146 11.6116 13.0573C11.7004 12.9692 11.8196 12.9157 11.952 12.9157H13.8807C13.8508 12.9563 13.8201 12.9937 13.7891 13.023ZM14.1686 12.1445C14.1686 12.2018 14.1582 12.2681 14.1424 12.3373H11.952C11.3669 12.3384 10.8929 12.8125 10.8917 13.3975V15.3983C10.8229 15.4122 10.7566 15.4216 10.699 15.4216H4.14494C4.04026 15.4216 3.95219 15.3333 3.95219 15.2289V2.89156C3.95219 2.78709 4.04029 2.69881 4.14494 2.69881H13.9762C14.0807 2.69881 14.169 2.78709 14.169 2.89156L14.1686 12.1445Z"
                fill="currentColor"
            />
            <path
                d="M11.8556 4.81909H6.26523C6.10542 4.81909 5.97607 4.94843 5.97607 5.10825C5.97607 5.26806 6.10545 5.3974 6.26523 5.3974H11.8556C12.0152 5.3974 12.1447 5.26806 12.1447 5.10825C12.1447 4.94843 12.0152 4.81909 11.8556 4.81909Z"
                fill="currentColor"
            />
            <path
                d="M11.8556 8.6749H6.26523C6.10542 8.6749 5.97607 8.80424 5.97607 8.96405C5.97607 9.12386 6.10545 9.25321 6.26523 9.25321H11.8556C12.0152 9.25321 12.1447 9.12386 12.1447 8.96405C12.1447 8.80421 12.0152 8.6749 11.8556 8.6749Z"
                fill="currentColor"
            />
            <path
                d="M9.35003 10.6021H6.26572C6.10591 10.6021 5.97656 10.7314 5.97656 10.8912C5.97656 11.051 6.10591 11.1804 6.26572 11.1804H9.35003C9.50965 11.1804 9.639 11.051 9.639 10.8912C9.639 10.7314 9.50965 10.6021 9.35003 10.6021Z"
                fill="currentColor"
            />
            <path
                d="M11.8556 6.74704H6.26523C6.10542 6.74704 5.97607 6.87638 5.97607 7.0362C5.97607 7.19601 6.10545 7.32535 6.26523 7.32535H11.8556C12.0152 7.32535 12.1447 7.19601 12.1447 7.0362C12.1447 6.87638 12.0152 6.74704 11.8556 6.74704Z"
                fill="currentColor"
            />
        </SvgIcon>
    );

export default CsvFileIcon;
