export const PERMISSIONS = {
    accountDimensions: "ACCOUNT-DIMENSIONS",
    
    admin: "ADMIN",
    
    users: {
        base: "USERS",
    },

    reports: {
        base: "REPORTS",
        otherUsersReports: "REPORTS-OTHER-USERS-REPORTS"
    },

    rides: "RIDES",

    tenantManagement: "TENANT-MANAGEMENT",

    travelInvoices: {
        base: "TRAVEL-INVOICES",
        approval: "TRAVEL-INVOICES-APPROVAL",
        repayment: "TRAVEL-INVOICES-REPAYMENT",
    },
}
