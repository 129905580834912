import DeleteOutlined from "src/icons/DeleteOutlined";
import DevicesApple from "src/icons/DevicesApple";
import DollarOutlined from "src/icons/DollarOutlined";
import FileOutlined from "src/icons/FileOutlined";
import Fingerprint from "src/icons/Fingerprint";
import Instagram from "src/icons/Instagram";
import Key from "src/icons/Key";
import Link from "src/icons/Link";
import LockOutlined from "src/icons/LockOutlined";
import NotificationOutlined from "src/icons/NotificationOutlined";
import PremiumOutlined from "src/icons/PremiumOutlined";
import SettingsOutlined from "src/icons/SettingsOutlined";
import UserOutlined from "src/icons/UserOutlined";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    NotificationOutlined,
    DeleteOutlined,
    DevicesApple,
    DollarOutlined,
    FileOutlined,
    Link,
    Key,
    LockOutlined,
    PremiumOutlined,
    SettingsOutlined,
    UserOutlined,
    Fingerprint,
    Instagram,
};
