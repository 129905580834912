const matkakuluApiBaseUrl = process.env.REACT_APP_API_BASEURL
export const MATKAKULU_API = {
    apiBaseUrl: matkakuluApiBaseUrl,

    admin: {
        contracts: {
            list: `${matkakuluApiBaseUrl}/api/admin/contract`,
            edit: `${matkakuluApiBaseUrl}/api/admin/contract`,
            getContract: (contractId: string) => `${matkakuluApiBaseUrl}/api/admin/contract/${contractId}`,
            terminate: (contractId: string) => `${matkakuluApiBaseUrl}/api/admin/contract/${contractId}/terminate`,
            reinstate: (contractId: string) => `${matkakuluApiBaseUrl}/api/admin/contract/${contractId}/reinstate`,
            getBillingBases: `${matkakuluApiBaseUrl}/api/admin/contract/get-billing-bases`,
        },

        invoices: {
            list: `${matkakuluApiBaseUrl}/api/admin/invoice`,
            getInvoice: (invoiceId: string) => `${matkakuluApiBaseUrl}/api/admin/invoice/${invoiceId}`,
            moveToSendingQueue: (invoiceId: string) => `${matkakuluApiBaseUrl}/api/admin/invoice/${invoiceId}/move-to-sending-queue`,
            refund:
                {
                    create: `${matkakuluApiBaseUrl}/api/admin/invoice/refund`,
                    delete: `${matkakuluApiBaseUrl}/api/admin/invoice/refund`,
                    getTenants: `${matkakuluApiBaseUrl}/api/admin/invoice/refund/get-tenants`,
                    getUninvoicedRows: `${matkakuluApiBaseUrl}/api/admin/invoice/refund/get-uninvoiced-rows`,
                }
        },

        getStatistics: `${matkakuluApiBaseUrl}/api/admin/get-statistics`,

        kilometerAllowance:
            {
                list: `${matkakuluApiBaseUrl}/api/admin/kilometer-allowance`,
                create: `${matkakuluApiBaseUrl}/api/admin/kilometer-allowance`,
                delete: `${matkakuluApiBaseUrl}/api/admin/kilometer-allowance`,
                edit: `${matkakuluApiBaseUrl}/api/admin/kilometer-allowance`,
                getKilometerAllowanceId: (kilometerAllowanceId: string) => `${matkakuluApiBaseUrl}/api/admin/kilometer-allowance/${kilometerAllowanceId}`,
                getAllowanceTypes: `${matkakuluApiBaseUrl}/api/admin/kilometer-allowance/get-allowance-types`,
                getVehicleTypeTypes: `${matkakuluApiBaseUrl}/api/admin/kilometer-allowance/get-vehicle-types`,

                configurationRow:
                    {
                        list: (kilometerAllowanceId: string) => `${matkakuluApiBaseUrl}/api/admin/kilometer-allowance/${kilometerAllowanceId}/configuration-row`,
                        create: (kilometerAllowanceId: string) => `${matkakuluApiBaseUrl}/api/admin/kilometer-allowance/${kilometerAllowanceId}/configuration-row`,
                        delete: (kilometerAllowanceId: string) => `${matkakuluApiBaseUrl}/api/admin/kilometer-allowance/${kilometerAllowanceId}/configuration-row`,
                        edit: (kilometerAllowanceId: string) => `${matkakuluApiBaseUrl}/api/admin/kilometer-allowance/${kilometerAllowanceId}/configuration-row`,
                    }
            },

        nightTravelAllowance:
            {
                list: `${matkakuluApiBaseUrl}/api/admin/night-travel-allowance`,
                create: `${matkakuluApiBaseUrl}/api/admin/night-travel-allowance`,
                delete: `${matkakuluApiBaseUrl}/api/admin/night-travel-allowance`,
                edit: `${matkakuluApiBaseUrl}/api/admin/night-travel-allowance`,
                getNightTravelAllowance: (nightTravelAllowanceId: string) => `${matkakuluApiBaseUrl}/api/admin/night-travel-allowance/${nightTravelAllowanceId}`,
            },

        mealCompensationAllowance:
            {
                list: `${matkakuluApiBaseUrl}/api/admin/meal-compensation-allowance`,
                create: `${matkakuluApiBaseUrl}/api/admin/meal-compensation-allowance`,
                delete: `${matkakuluApiBaseUrl}/api/admin/meal-compensation-allowance`,
                edit: `${matkakuluApiBaseUrl}/api/admin/meal-compensation-allowance`,
                getMealCompensationAllowance: (mealCompensationAllowanceId: string) => `${matkakuluApiBaseUrl}/api/admin/meal-compensation-allowance/${mealCompensationAllowanceId}`,
            },
        
        paymentTerm:
            {
                list: `${matkakuluApiBaseUrl}/api/admin/payment-term`,
                create: `${matkakuluApiBaseUrl}/api/admin/payment-term`,
                delete: `${matkakuluApiBaseUrl}/api/admin/payment-term`,
                edit: `${matkakuluApiBaseUrl}/api/admin/payment-term`,
                getPaymentTermsId: (paymentTermsId: string) => `${matkakuluApiBaseUrl}/api/admin/payment-term/${paymentTermsId}`,
            },

        perDiemAllowance:
            {
                list: `${matkakuluApiBaseUrl}/api/admin/per-diem-allowance`,
                create: `${matkakuluApiBaseUrl}/api/admin/per-diem-allowance`,
                delete: `${matkakuluApiBaseUrl}/api/admin/per-diem-allowance`,
                edit: `${matkakuluApiBaseUrl}/api/admin/per-diem-allowance`,
                getPerDiemAllowance: (perDiemAllowanceId: string) => `${matkakuluApiBaseUrl}/api/admin/per-diem-allowance/${perDiemAllowanceId}`,
                createDraft: `${matkakuluApiBaseUrl}/api/admin/per-diem-allowance/create-draft`,

                configurationRow:
                    {
                        list: (perDiemAllowanceRowId: string) => `${matkakuluApiBaseUrl}/api/admin/per-diem-allowance/${perDiemAllowanceRowId}/configuration-row`,
                        create: (perDiemAllowanceRowId: string) => `${matkakuluApiBaseUrl}/api/admin/per-diem-allowance/${perDiemAllowanceRowId}/configuration-row`,
                        delete: (perDiemAllowanceRowId: string) => `${matkakuluApiBaseUrl}/api/admin/per-diem-allowance/${perDiemAllowanceRowId}/configuration-row`,
                        edit: (perDiemAllowanceRowId: string) => `${matkakuluApiBaseUrl}/api/admin/per-diem-allowance/${perDiemAllowanceRowId}/configuration-row`,
                    }
            },

        products:
            {
                list: `${matkakuluApiBaseUrl}/api/admin/product`,
                create: `${matkakuluApiBaseUrl}/api/admin/product`,
                delete: `${matkakuluApiBaseUrl}/api/admin/product`,
                edit: `${matkakuluApiBaseUrl}/api/admin/product`,
                getProduct: (productId: string) => `${matkakuluApiBaseUrl}/api/admin/product/${productId}`,
                getProducts: `${matkakuluApiBaseUrl}/api/admin/product/get-products`,
                getTaxRates: `${matkakuluApiBaseUrl}/api/admin/product/get-tax-rates`,
            },

        sellers:
            {
                activate: `${matkakuluApiBaseUrl}/api/admin/seller/activate`,
                deactivate: `${matkakuluApiBaseUrl}/api/admin/seller/deactivate`,
                list: `${matkakuluApiBaseUrl}/api/admin/seller`,
                create: `${matkakuluApiBaseUrl}/api/admin/seller`,
                edit: `${matkakuluApiBaseUrl}/api/admin/seller`,
                updateConnections: `${matkakuluApiBaseUrl}/api/admin/seller/connection`,
                delete: `${matkakuluApiBaseUrl}/api/admin/seller`,
                defaultRecurrenceType: `${matkakuluApiBaseUrl}/api/admin/seller/recurrence-type`,
                defaultCommissionType: `${matkakuluApiBaseUrl}/api/admin/seller/commission-type`,
                getSeller: (sellerId: string) => `${matkakuluApiBaseUrl}/api/admin/seller/${sellerId}`,
                getSellerConnections: (sellerId: string) => `${matkakuluApiBaseUrl}/api/admin/seller/get-connections/${sellerId}`,
                getUsersByName: (searchValue: string) => `${matkakuluApiBaseUrl}/api/admin/seller/get-users-by-name/${searchValue}`,
                getUserById: (userId: string) => `${matkakuluApiBaseUrl}/api/admin/seller/get-user-by-id/${userId}`,
                getTenantById: (tenantId: string) => `${matkakuluApiBaseUrl}/api/admin/seller/get-tenant-by-id/${tenantId}`,
                getTenants: (searchValue: string) => `${matkakuluApiBaseUrl}/api/admin/seller/get-tenants/${searchValue}`,
            },

        status: {
            getStatus: (service: string) => `${matkakuluApiBaseUrl}/api/admin/status/get-status/${service}`,
        },

        systemSettings:
            {
                list: `${matkakuluApiBaseUrl}/api/admin/system-settings`,
                edit: `${matkakuluApiBaseUrl}/api/admin/system-settings`,
                getInvoiceTransferTargetsForAccounting: `${matkakuluApiBaseUrl}/api/admin/system-settings/get-invoice-transfer-targets-for-accounting`,
            },

        taxRates:
            {
                list: `${matkakuluApiBaseUrl}/api/admin/tax-rate`,
                create: `${matkakuluApiBaseUrl}/api/admin/tax-rate`,
                delete: `${matkakuluApiBaseUrl}/api/admin/tax-rate`,
                edit: (taxRateId: string) => `${matkakuluApiBaseUrl}/api/admin/tax-rate/${taxRateId}`,
                getTaxRate: (taxRateId: string) => `${matkakuluApiBaseUrl}/api/admin/tax-rate/${taxRateId}`,

                taxRateRow: {
                    list: (taxRateId: string) => `${matkakuluApiBaseUrl}/api/admin/tax-rate/${taxRateId}/tax-rate-row/`,
                    create: (taxRateId: string) => `${matkakuluApiBaseUrl}/api/admin/tax-rate/${taxRateId}/tax-rate-row/`,
                    delete: (taxRateId: string) => `${matkakuluApiBaseUrl}/api/admin/tax-rate/${taxRateId}/tax-rate-row/`,
                    edit: (taxRateId: string, taxRateRowId: string) => `${matkakuluApiBaseUrl}/api/admin/tax-rate/${taxRateId}/tax-rate-row/${taxRateRowId}`,
                    getTaxRateRow: (taxRateId: string, taxRateRowId: string) => `${matkakuluApiBaseUrl}/api/admin/tax-rate/${taxRateId}/tax-rate-row/${taxRateRowId}`
                }
            },

        tenants:
            {
                list: `${matkakuluApiBaseUrl}/api/admin/tenant`,
                edit: `${matkakuluApiBaseUrl}/api/admin/tenant`,
                enableTenant: (tenantId: string) => `${matkakuluApiBaseUrl}/api/admin/tenant/${tenantId}/enable`,
                disableTenant: (tenantId: string) => `${matkakuluApiBaseUrl}/api/admin/tenant/${tenantId}/disable`,                
                getTenant: (tenantId: string) => `${matkakuluApiBaseUrl}/api/admin/tenant/${tenantId}`,

                invites: {
                    list: `${matkakuluApiBaseUrl}/api/admin/tenant/invite`,
                    create: `${matkakuluApiBaseUrl}/api/admin/tenant/invite`,
                    getSellers: `${matkakuluApiBaseUrl}/api/admin/tenant/invite/get-sellers`,
                    cancel: (id: string) => `${matkakuluApiBaseUrl}/api/admin/tenant/invite/${id}`,
                },

                testAccounts: {
                    list: `${matkakuluApiBaseUrl}/api/admin/tenant/test-account`,
                    covertToLiveAccount: `${matkakuluApiBaseUrl}/api/admin/tenant/test-account/convert-to-live-account`,
                },

                productPricing:
                    {
                        list: (tenantId: string) => `${matkakuluApiBaseUrl}/api/admin/tenant/${tenantId}/product-pricing`,
                        create: (tenantId: string) => `${matkakuluApiBaseUrl}/api/admin/tenant/${tenantId}/product-pricing`,
                        edit: (tenantId: string, id: string) => `${matkakuluApiBaseUrl}/api/admin/tenant/${tenantId}/product-pricing/${id}`,
                        delete: `${matkakuluApiBaseUrl}/api/admin/tenant/product-pricing/`,
                        pricingType: `${matkakuluApiBaseUrl}/api/admin/tenant/product-pricing/pricing-types`,
                        getProductPriceData: (id: string) => `${matkakuluApiBaseUrl}/api/admin/tenant/product-pricing/${id}`,
                    },

                approvalProcess: {
                    getApprovalRoles: `${matkakuluApiBaseUrl}/api/admin/approval-process/get-roles`,
                    getApprovalProcessSettings: `${matkakuluApiBaseUrl}/api/admin/approval-process`,
                    edit: `${matkakuluApiBaseUrl}/api/admin/approval-process`,
                },
            },
    },

    login: `${matkakuluApiBaseUrl}/api/login`,

    logout: `${matkakuluApiBaseUrl}/api/logout`,

    cron:
        {
            cron: `${matkakuluApiBaseUrl}/api/cron`,
        },

    notification:
        {
            list: `${matkakuluApiBaseUrl}/api/notification`,
            markAsSeen: `${matkakuluApiBaseUrl}/api/notification/mark-as-seen`,
        },

    vehicle:
        {
            list: `${matkakuluApiBaseUrl}/api/vehicle`,
            create: `${matkakuluApiBaseUrl}/api/vehicle`,
            delete: `${matkakuluApiBaseUrl}/api/vehicle`,
            edit: `${matkakuluApiBaseUrl}/api/vehicle`,
            getVehicleTypes: `${matkakuluApiBaseUrl}/api/vehicle/get-vehicle-types`,
            getVehicle: (vehicleId: string) => `${matkakuluApiBaseUrl}/api/vehicle/${vehicleId}`
        },

    user:
        {
            list: `${matkakuluApiBaseUrl}/api/user`,
            create: `${matkakuluApiBaseUrl}/api/user`,
            delete: `${matkakuluApiBaseUrl}/api/user`,
            edit: `${matkakuluApiBaseUrl}/api/user`,
            avatar: `${matkakuluApiBaseUrl}/api/user/avatar`,
            getAvatar: (avatarId: string) => `${matkakuluApiBaseUrl}/api/user/avatar/${avatarId}`,
            getUser: (userId: string) => `${matkakuluApiBaseUrl}/api/user/${userId}`,
            invite: `${matkakuluApiBaseUrl}/api/user/invite`,
            role: `${matkakuluApiBaseUrl}/api/user/role`,
            getUserTenants: `${matkakuluApiBaseUrl}/api/user/get-user-tenants`,
            switchTenant: `${matkakuluApiBaseUrl}/api/user/switch-tenant`,
            roles: {
                create: (userId: string) => `${matkakuluApiBaseUrl}/api/user/${userId}/role`,
                delete: (userId: string) => `${matkakuluApiBaseUrl}/api/user/${userId}/role`,
                edit: (userId: string, tenantUserRoleId: string) => `${matkakuluApiBaseUrl}/api/user/${userId}/role/${tenantUserRoleId}`,
                list: (userId: string) => `${matkakuluApiBaseUrl}/api/user/${userId}/role`,
                getRole: (userId: string, tenantUserRoleId: string) => `${matkakuluApiBaseUrl}/api/user/${userId}/role/${tenantUserRoleId}`,
                getAddableRoles: (userId: string) => `${matkakuluApiBaseUrl}/api/user/${userId}/role/get-addable-roles`,
            },
            profile:
                {
                    getProfile: `${matkakuluApiBaseUrl}/api/user/profile`,
                    getSettings: `${matkakuluApiBaseUrl}/api/user/profile/settings`,
                    saveSettings: `${matkakuluApiBaseUrl}/api/user/profile/settings`,
                    getNotificationSettings: `${matkakuluApiBaseUrl}/api/user/profile/notification-settings`,
                    saveNotificationSettings: `${matkakuluApiBaseUrl}/api/user/profile/notification-settings`,
                }
        },

    role:
        {
            list: `${matkakuluApiBaseUrl}/api/admin/role`,
            create: `${matkakuluApiBaseUrl}/api/admin/role`,
            delete: `${matkakuluApiBaseUrl}/api/admin/role`,
            edit: `${matkakuluApiBaseUrl}/api/admin/role`,
            permissions: `${matkakuluApiBaseUrl}/api/admin/role/get-permissions`,
            getRolePermission: (roleId: string) => `${matkakuluApiBaseUrl}/api/admin/role/${roleId}/permissions`,
            getRole: (roleId: string) => `${matkakuluApiBaseUrl}/api/admin/role/${roleId}`,
            togglePermission: `${matkakuluApiBaseUrl}/api/admin/role/toggle-permission`,
        },

    ride:
        {
            create: `${matkakuluApiBaseUrl}/api/ride`,
            createDraft: `${matkakuluApiBaseUrl}/api/ride/create-draft`,
            saveDraft: `${matkakuluApiBaseUrl}/api/ride/save-draft`,
            delete: `${matkakuluApiBaseUrl}/api/ride`,
            edit: `${matkakuluApiBaseUrl}/api/ride`,
            getAddressBookItem: (addressBookId: string) => `${matkakuluApiBaseUrl}/api/ride/get-address-book-item/${addressBookId}`,
            getAddressBookItems: `${matkakuluApiBaseUrl}/api/ride/get-address-book-items`,
            getRide: (rideId: string) => `${matkakuluApiBaseUrl}/api/ride/${rideId}`,
            getRouteInfo: `${matkakuluApiBaseUrl}/api/ride/get-route-info`,
            getTemplate: (templateId: string) => `${matkakuluApiBaseUrl}/api/ride/get-template/${templateId}`,
            getTemplates: `${matkakuluApiBaseUrl}/api/ride/get-templates`,
            getVehicleTypes: `${matkakuluApiBaseUrl}/api/ride/get-vehicle-types`,
            getVehicles: `${matkakuluApiBaseUrl}/api/ride/get-vehicles`,
            list: `${matkakuluApiBaseUrl}/api/ride`,

            passengers: {
                add: (rideId: string) => `${matkakuluApiBaseUrl}/api/ride/${rideId}/passenger`,
                delete: (rideId: string) => `${matkakuluApiBaseUrl}/api/ride/${rideId}/passenger`,
                get: (rideId: string, passengerId: string) => `${matkakuluApiBaseUrl}/api/ride/${rideId}/passenger/${passengerId}`,
                edit: (rideId: string, passengerId: string) => `${matkakuluApiBaseUrl}/api/ride/${rideId}/passenger/${passengerId}`,
            },
        },

    rideTemplate:
        {
            list: `${matkakuluApiBaseUrl}/api/ride-template`,
            create: `${matkakuluApiBaseUrl}/api/ride-template`,
            delete: `${matkakuluApiBaseUrl}/api/ride-template`,
            edit: (templateId: string) => `${matkakuluApiBaseUrl}/api/ride-template/${templateId}`,
            getRideTemplate: (templateId: string) => `${matkakuluApiBaseUrl}/api/ride-template/${templateId}`,
        },

    signUp:
        {
            registerUser: `${matkakuluApiBaseUrl}/api/sign-up/user`,
            registerTenant: `${matkakuluApiBaseUrl}/api/sign-up/tenant`,
            getUserInviteData: (id: string) => `${matkakuluApiBaseUrl}/api/sign-up/user/get-invite-data/${id}`,
            getTenantInviteData: (id: string) => `${matkakuluApiBaseUrl}/api/sign-up/tenant/get-invite-data/${id}`,
        },

    report:
        {
            list: `${matkakuluApiBaseUrl}/api/reports`,
            filterData: `${matkakuluApiBaseUrl}/api/reports/filter-data`,
            monthly: `${matkakuluApiBaseUrl}/api/reports/get-monthly-report`,
            travelInvoice: {
                getReport: `${matkakuluApiBaseUrl}/api/reports/travel-invoice-report`,
                getUsers: `${matkakuluApiBaseUrl}/api/reports/travel-invoice-report/get-users`
            }
        },

    attachment:
        {
            list: `${matkakuluApiBaseUrl}/api/attachment`,
            create: `${matkakuluApiBaseUrl}/api/attachment`,
            delete: `${matkakuluApiBaseUrl}/api/attachment`,
            edit: `${matkakuluApiBaseUrl}/api/attachment`,
            getImage: (attachmentPictureId: string) => `${matkakuluApiBaseUrl}/api/attachment/get-image/${attachmentPictureId}`,
            getAttachment: (attachmentId: string) => `${matkakuluApiBaseUrl}/api/attachment/get-image/${attachmentId}`,
        },

    recurringRide:
        {
            list: `${matkakuluApiBaseUrl}/api/recurring-ride`,
            create: `${matkakuluApiBaseUrl}/api/recurring-ride`,
            delete: `${matkakuluApiBaseUrl}/api/recurring-ride`,
            edit: `${matkakuluApiBaseUrl}/api/recurring-ride`,
            createDraft: `${matkakuluApiBaseUrl}/api/recurring-ride/create-draft`,
            getVehicles: `${matkakuluApiBaseUrl}/api/recurring-ride/get-vehicles`,
            getVehicleTypes: `${matkakuluApiBaseUrl}/api/recurring-ride/get-vehicle-types`,
            getMonthlyRecurrenceTypeDropDownData: `${matkakuluApiBaseUrl}/api/recurring-ride/get-monthly-recurrence-data`,
            getRecurringRide: (rideId: string) => `${matkakuluApiBaseUrl}/api/recurring-ride/${rideId}`,
            getDestinationRow: (rideId: string) => `${matkakuluApiBaseUrl}/api/recurring-ride/${rideId}/destination`,
            getDestination: (id: string) => `${matkakuluApiBaseUrl}/api/recurring-ride/${id}/destination`,
            deleteDestination: (id: string) => `${matkakuluApiBaseUrl}/api/recurring-ride/${id}/destination`,
            getAutocomplete: (searchValue: string) => `${matkakuluApiBaseUrl}/api/recurring-ride/autocomplete?address=${searchValue}`,
        },

    settings:
        {
            index: `${matkakuluApiBaseUrl}/api/settings`,
        },

    provisions:
        {
            getProvisions: `${matkakuluApiBaseUrl}/api/admin/provision`,
            addPayment: `${matkakuluApiBaseUrl}/api/admin/provision/add-payment`,
            getSellers: `${matkakuluApiBaseUrl}/api/admin/provision/get-sellers`,
            getPaymentHistory: `${matkakuluApiBaseUrl}/api/admin/provision/get-payment-history`,
        },

    addressBook:
        {
            list: `${matkakuluApiBaseUrl}/api/address-book`,
            create: `${matkakuluApiBaseUrl}/api/address-book`,
            delete: `${matkakuluApiBaseUrl}/api/address-book`,
            edit: `${matkakuluApiBaseUrl}/api/address-book`,
            getAddressBook: (addressBookId: string) => `${matkakuluApiBaseUrl}/api/address-book/${addressBookId}`,
        },

    accountDimensions:
        {
            list: `${matkakuluApiBaseUrl}/api/account-dimension`,
            create: `${matkakuluApiBaseUrl}/api/account-dimension`,
            delete: `${matkakuluApiBaseUrl}/api/account-dimension`,
            edit: (accountDimensionId: string) => `${matkakuluApiBaseUrl}/api/account-dimension/${accountDimensionId}`,
            getAccountDimension: (accountDimensionId: string) => `${matkakuluApiBaseUrl}/api/account-dimension/${accountDimensionId}`,
        },

    tenantManagement:
        {
            getBasicInformation: `${matkakuluApiBaseUrl}/api/tenant-management/get-basic-information`,
            saveBasicInformation: `${matkakuluApiBaseUrl}/api/tenant-management/basic-information`,
            getBillingInformation: `${matkakuluApiBaseUrl}/api/tenant-management/get-billing-information`,
            saveBillingInformation: `${matkakuluApiBaseUrl}/api/tenant-management/billing-information`,
            getBillingTypes: `${matkakuluApiBaseUrl}/api/tenant-management/get-billing-types`,
            saveTenantSettings: `${matkakuluApiBaseUrl}/api/tenant-management/settings`,
            getTenantSettings: `${matkakuluApiBaseUrl}/api/tenant-management/get-settings`,
        },

    dashboard:
        {
            getWidgetSelect: `${matkakuluApiBaseUrl}/api/dashboard/widget/get-widgets`,
            getUserWidgetSelect: `${matkakuluApiBaseUrl}/api/dashboard/widget/get-user-widgets`,
            saveWidgetSelection: `${matkakuluApiBaseUrl}/api/dashboard/widget`,
            delete: (userWidgetId: string) => `${matkakuluApiBaseUrl}/api/dashboard/widget/${userWidgetId}`,
            
            ride: {
                getRideData: `${matkakuluApiBaseUrl}/api/dashboard/widget/ride/get-data`,
                startRide: `${matkakuluApiBaseUrl}/api/dashboard/widget/ride/start-ride`,
                endRide: `${matkakuluApiBaseUrl}/api/dashboard/widget/ride/end-ride`,
                addLeg: `${matkakuluApiBaseUrl}/api/dashboard/widget/ride/add-leg`,
                getPlaceTypesFromGpsLocation: `${matkakuluApiBaseUrl}/api/dashboard/widget/ride/get-gps-location`,
            }
        },


    // TODO KK: Loginin failaukseen redirecti johonkin sivulle joka ei ole authguardin sisällä

    travelInvoices: {

        repayment: {
            getTravelInvoices: `${matkakuluApiBaseUrl}/api/travel-invoice/repayment/get-travel-invoices`,
            markAsPaid: `${matkakuluApiBaseUrl}/api/travel-invoice/repayment/mark-as-paid`,
            cancelRepayment: `${matkakuluApiBaseUrl}/api/travel-invoice/repayment/cancel-repayment`,
            getUsers: `${matkakuluApiBaseUrl}/api/travel-invoice/repayment/get-users`,
        },

        kaksi: {
            getTravelInvoice: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}`,
            sendForApproval: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/send-for-approval`,
            edit: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}`,

            expense: {
                createDraft: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/expense/create-draft`,
                edit: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/expense`,
                delete: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/expense`,
                getExpense: (travelInvoiceId: string, expenseId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/expense/${expenseId}`,
                copyExpense: (travelInvoiceId: string, expenseId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/expense/${expenseId}/copy`,
                getAccountDimensions: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/expense/get-account-dimensions`,

                attachment: {
                    upload: (travelInvoiceId: string, expenseId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/expense/${expenseId}/attachment`,
                    delete: (travelInvoiceId: string, expenseId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/expense/${expenseId}/attachment`,
                    edit: (travelInvoiceId: string, expenseId: string, expenseAttachmentId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/expense/${expenseId}/attachment/${expenseAttachmentId}`,
                    getFile: (travelInvoiceId: string, expenseId: string, expenseAttachmentId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/expense/${expenseId}/attachment/${expenseAttachmentId}/file`,
                    getInfo: (travelInvoiceId: string, expenseId: string, expenseAttachmentId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/expense/${expenseId}/attachment/${expenseAttachmentId}`,
                }
            },

            ride: {
                copyRide: (travelInvoiceId: string, rideId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/${rideId}/copy`,
                createDraft: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/create-draft`,
                delete: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride`,
                edit: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride`,
                getAccountDimensions: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/get-account-dimensions`,
                getAddressBookItem: (travelInvoiceId: string, addressBookId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/get-address-book-item/${addressBookId}`,
                getAddressBookItems: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/get-address-book-items`,
                getImportableRides: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/get-importable-rides`,
                getRide: (travelInvoiceId: string, rideId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/${rideId}`,
                getRideOrigin: (travelInvoiceId: string, rideId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/get-ride-origin/${rideId}`,
                getRouteInfo: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/get-route-info`,
                getTemplate: (travelInvoiceId: string, templateId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/get-template/${templateId}`,
                getTemplates: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/get-templates`,
                getVehicleTypes: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/get-vehicle-types`,
                getVehicles: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/get-vehicles`,
                importRides: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/import-rides`,
                getPlaceTypesFromGpsLocation: `${matkakuluApiBaseUrl}/api/travel-invoice/ride/get-gps-location`,
                returnRideToOrigin: (travelInvoiceId: string, rideId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/return-to-origin/${rideId}`,

                passengers: {
                    add: (travelInvoiceId: string, rideId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/${rideId}/passenger`,
                    delete: (travelInvoiceId: string, rideId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/${rideId}/passenger`,
                    get: (travelInvoiceId: string, rideId: string, passengerId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/${rideId}/passenger/${passengerId}`,
                    edit: (travelInvoiceId: string, rideId: string, passengerId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/ride/${rideId}/passenger/${passengerId}`,
                },
            },

            trip: {
                create: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/trip`,
                edit: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/trip`,
                delete: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/trip`,
                getTrip: (travelInvoiceId: string, tripId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/trip/${tripId}`,
                getTripDays: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/trip/get-trip-days`,
            },

            workTime: {
                create: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/work-time`,
                edit: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/work-time`,
                delete: (travelInvoiceId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/work-time`,
                getWorkTime: (travelInvoiceId: string, workTimeId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}/work-time/${workTimeId}`,
            },
        },

        getExpenseTypes: `${matkakuluApiBaseUrl}/api/travel-invoice/get-expense-types`,
        getTravelInvoice: (travelInvoiceId: string | undefined) => `${matkakuluApiBaseUrl}/api/travel-invoice/${travelInvoiceId}`,
        createDraft: `${matkakuluApiBaseUrl}/api/travel-invoice/create-draft`,
        edit: `${matkakuluApiBaseUrl}/api/travel-invoice`,
        list: `${matkakuluApiBaseUrl}/api/travel-invoice`,
        delete: `${matkakuluApiBaseUrl}/api/travel-invoice`,
        generalInformation: `${matkakuluApiBaseUrl}/api/travel-invoice/general`,
        tripInformation: `${matkakuluApiBaseUrl}/api/travel-invoice/trip`,
        perDiemInformation: `${matkakuluApiBaseUrl}/api/travel-invoice/per-diem`,
        // Kato reitit mitä käytetään ja mitä ei
        routeInformation: `${matkakuluApiBaseUrl}/api/travel-invoice/route-information`,
        expenseInformation: `${matkakuluApiBaseUrl}/api/travel-invoice/expense`,
        summaryInformation: `${matkakuluApiBaseUrl}/api/travel-invoice/summary`,
        saveTravelInvoice: `${matkakuluApiBaseUrl}/api/travel-invoice/save-travel-invoice`,
        getApprovableTravelInvoice: `${matkakuluApiBaseUrl}/api/travel-invoice/get-approvable-travel-invoices`,

        route: {
            createDraft: `${matkakuluApiBaseUrl}/api/travel-invoice/route/create-draft`,
            delete: `${matkakuluApiBaseUrl}/api/travel-invoice/route`,
            edit: `${matkakuluApiBaseUrl}/api/travel-invoice/route`,
            getVehicles: `${matkakuluApiBaseUrl}/api/travel-invoice/route/get-vehicles`,
            rideRow: `${matkakuluApiBaseUrl}/api/travel-invoice/route/ride-row`,
            // TODO KK: Kato tää läpi, onko jotain hyödynnettävää
            autocomplete: (searchValue: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/route/autocomplete?address=${searchValue}`,
            getRoute: (routeId: string) => `${matkakuluApiBaseUrl}/api/travel-invoice/route/${routeId}`,
        },

        approval:
            {
                approve: `${matkakuluApiBaseUrl}/api/travel-invoice/approve`,
                reject: `${matkakuluApiBaseUrl}/api/travel-invoice/reject`,
                list: `${matkakuluApiBaseUrl}/api/travel-invoice/approve`,
                getUserData: `${matkakuluApiBaseUrl}/api/travel-invoice/approve/get-user-data`,
                getTravelInvoices: `${matkakuluApiBaseUrl}/api/travel-invoice/approve/get-travel-invoices`,
            },
    },


    // Huom. Reittien nimet pitää kuvastaa mitä ne tekee. Create - luo, edit - muokkaa, getSellers - hae myyjät, jne.
    // esimerkkinä signupinvitations.sellers on vähän huono nimi, ei kerro yksiselitteisesti hakeeko myyjät, päivittääkö vai muuttaako myyjiä jne 
}
