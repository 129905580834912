import {alpha, Box, ButtonBase, styled} from "@mui/material";
import {Paragraph, Span} from "src/components/Typography";
import React, {FC, useMemo} from "react";
import {Link, useLocation} from "react-router-dom";
import {getNavigation, NavigationItem} from "../layout-parts/navigation";
import SidebarAccordion from "./SidebarAccordion";
import useSessionUser from "../../resources/hooks/useSessionUser";


type Active = { active: any };
type Compact = { compact: number };
type ActiveCompact = Active & Compact;

const NavItemButton = styled(ButtonBase)<Active>(({theme, active}) => ({
    height: 44,
    width: "100%",
    borderRadius: 8,
    marginBottom: 4,
    padding: "0 18px",
    justifyContent: "flex-start",
    transition: "all 0.15s ease",
    ...(active && {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.06),
    }),
    "&:hover": {backgroundColor: theme.palette.action.hover},
}));

const ListLabel = styled(Paragraph)<Compact>(({theme, compact}) => ({
    fontWeight: 700,
    fontSize: "12px",
    marginTop: "20px",
    marginLeft: "15px",
    marginBottom: "10px",
    textTransform: "uppercase",
    transition: "all 0.15s ease",
    color: theme.palette.text.secondary,
    ...(compact && {opacity: 0, width: 0}),
}));

const ExternalLink = styled("a")(({theme}) => ({
    overflow: "hidden",
    whiteSpace: "pre",
    marginBottom: "8px",
    textDecoration: "none",
    color: theme.palette.text.primary,
}));

const StyledText = styled(Span)<ActiveCompact>(({theme, compact, active}) => ({
    whiteSpace: "nowrap",
    paddingLeft: "0.8rem",
    transition: "all 0.15s ease",
    fontSize: "13px",
    fontWeight: 500,
    marginLeft: 25,
    color: active ? theme.palette.primary.main : theme.palette.text.secondary,
    ...(compact && {opacity: 0, width: 0}),
}));

const BulletIcon = styled("div")<Active>(({theme, active}) => ({
    width: 4,
    height: 4,
    marginLeft: "10px",
    marginRight: "8px",
    overflow: "hidden",
    borderRadius: "50%",
    background: active ? theme.palette.primary.main : theme.palette.text.disabled,
    boxShadow: active ? `0px 0px 0px 3px ${theme.palette.primary[200]}` : "none",
}));

const BadgeValue = styled(Box)<Compact>(({compact, theme}) => ({
    color: "white",
    fontSize: "12px",
    fontWeight: 500,
    padding: "1px 6px",
    overflow: "hidden",
    borderRadius: "300px",
    backgroundColor: theme.palette.primary.main,
    display: compact ? "none" : "unset",
}));

// Common icon style
const iconStyle = (active: any) => ({
    fontSize: 18,
    marginRight: "-25px",
    // marginLeft: "20px",
    color: active ? "primary.main" : "text.secondary",
});

// ---------------------------------------------------------------
type MultiLevelMenuProps = { 
    sidebarCompact: boolean
    onClick?: () => void
};
// ---------------------------------------------------------------

const MultiLevelMenu: FC<MultiLevelMenuProps> = ({sidebarCompact, onClick}) => {
    const {pathname} = useLocation();
    const navigation = useMemo(() => getNavigation(), []);
    const {sessionUser} = useSessionUser();

    // handle active current page
    const activeRoute = (path: string) => (pathname.indexOf(path) >= 0 ? 1 : 0);

    // ACTIVATE SIDEBAR COMPACT
    const COMPACT = sidebarCompact ? 1 : 0;
    //   RECURSIVE FUNCTION TO RENDER MULTI LEVEL MENU
    
    const handleClick = () => {
        if (onClick != null) onClick();
    }
    
    const renderLevels = (data: NavigationItem[], indent: boolean) => data.map((item: any, index: any) => {
        let hasAllRequiredPermissions = true;
        item.requiredPermissions.forEach((permission: string) => {
            if (!sessionUser.permissions.includes(permission)) {
                hasAllRequiredPermissions = false;
            }
        })

        if (!hasAllRequiredPermissions) {
            return <React.Fragment key={index}> </React.Fragment>
        }

        if (item.type === "label")
            return (
                <ListLabel key={index} compact={COMPACT}>
                    {item.label}
                </ListLabel>
            );

        if (item.children) {
            return (
                <SidebarAccordion key={index} item={item} sidebarCompact={COMPACT}>
                    {renderLevels(item.children, true)}
                </SidebarAccordion>
            );
        }

        // if (item.type === "extLink") {
        //     return (
        //         <ExternalLink key={index} href={item.path} rel="noopener noreferrer" target="_blank">
        //             <NavItemButton key={item.name} name="child" active={0}>
        //                 {(() => {
        //                     if (item.icon) {
        //                         return <item.icon sx={iconStyle(0)} />;
        //                     }
        //                     return <span className="item-icon icon-text">{item.iconText}</span>;
        //
        //                 })()}
        //
        //                 <StyledText compact={COMPACT} active={activeRoute(item.path)}>
        //                     {item.name}
        //                 </StyledText>
        //
        //                 <Box mx="auto" />
        //
        //                 {item.badge && <BadgeValue compact={COMPACT}>{item.badge.value}</BadgeValue>}
        //             </NavItemButton>
        //         </ExternalLink>
        //     );
        // }

        return (
            <Box key={index}
                 component={Link}
                 to={item.path}
                 onClick={handleClick}
            >
                <NavItemButton
                    key={item.name}
                    className="navItem"
                    sx={{pl: indent ? 6 : 2}}
                    active={activeRoute(item.path)}
                >
                    {item?.icon ? (
                        <item.icon sx={iconStyle(activeRoute(item.path))} />
                    ) : (
                        <BulletIcon active={activeRoute(item.path)} />
                    )}

                    <StyledText compact={COMPACT} active={activeRoute(item.path)}>
                        {item.name}
                    </StyledText>

                    {item.badge && <BadgeValue compact={COMPACT}>{item.badge.value}</BadgeValue>}
                </NavItemButton>
            </Box>
        );

    });

    return <>{renderLevels(navigation, false)}</>;
};

export default MultiLevelMenu;
