import {FC, lazy, LazyExoticComponent, Suspense} from "react";
import LoadingScreen from "src/components/LoadingScreen";
import LayoutV3 from "src/layouts/layout-v3/DashboardLayout";
import {PermissionGuard} from "src/page-sections/authentication/PermissionGuard";
import AuthGuard from "../page-sections/authentication/AuthGuard";
import {PERMISSIONS} from "../resources/permissions";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) => (
    <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
    </Suspense>
);

const Logout = Loadable(lazy(() => import("../pages/Logout")));
const Users = Loadable(lazy(() => import("../pages/users/list")));
const UserCreate = Loadable(lazy(() => import("../pages/users/create")));
const UserEdit = Loadable(lazy(() => import("../pages/users/edit")));
const Vehicles = Loadable(lazy(() => import("../pages/vehicles/list/index")));
const VehicleCreate = Loadable(lazy(() => import("../pages/vehicles/create")));
const VehicleEdit = Loadable(lazy(() => import("../pages/vehicles/edit")));
const TaxRates = Loadable(lazy(() => import("../pages/admin/tax-rates/list")));
const TaxRatesCreate = Loadable(lazy(() => import("../pages/admin/tax-rates/create/taxRateCreate")));
const TaxRatesEdit = Loadable(lazy(() => import("../pages/admin/tax-rates/edit")));
const TaxRatesRowCreate = Loadable(lazy(() => import("../pages/admin/tax-rates/create/taxRateRowCreate")));
const TaxRatesRowEdit = Loadable(lazy(() => import("../pages/admin/tax-rates/edit/taxRateRowEdit")));
const TenantManagement = Loadable(lazy(() => import("../pages/tenant-management")));
const SystemSettings = Loadable(lazy(() => import("../pages/admin/system-settings")));
const Contracts = Loadable(lazy(() => import("../pages/admin/contracts/list")));
const ContractsEdit = Loadable(lazy(() => import("../pages/admin/contracts/edit")));
const PaymentTerms = Loadable(lazy(() => import("../pages/admin/payment-terms/list")));
const PaymentTermsCreate = Loadable(lazy(() => import("../pages/admin/payment-terms/create")));
const PaymentTermsEdit = Loadable(lazy(() => import("../pages/admin/payment-terms/edit")));
const Sellers = Loadable(lazy(() => import("../pages/admin/sellers/list")));
const SellersCreate = Loadable(lazy(() => import("../pages/admin/sellers/create")));
const SellersEdit = Loadable(lazy(() => import("../pages/admin/sellers/edit")));
const RecurringRideEdit = Loadable(lazy(() => import("../pages/rides/recurring-rides/edit")));
const Roles = Loadable(lazy(() => import("../pages/admin/roles/list")));
const RolesEdit = Loadable(lazy(() => import("../pages/admin/roles/edit")));
const RolesCreate = Loadable(lazy(() => import("../pages/admin/roles/create")));
const ReportMonthly = Loadable(lazy(() => import("../pages/reports/monthly")));
const ReportTravelInvoice = Loadable(lazy(() => import("../pages/reports/travel-invoice-report")));
const ProfilePage = Loadable(lazy(() => import("../pages/profile-page")));
const Products = Loadable(lazy(() => import("../pages/admin/products/list")));
const ProductsEdit = Loadable(lazy(() => import("../pages/admin/products/edit")));
const ProductsCreate = Loadable(lazy(() => import("../pages/admin/products/create")));
const Provisions = Loadable(lazy(() => import("../pages/admin/provisions")));
const Tenants = Loadable(lazy(() => import("../pages/admin/tenants")));
const TenantEdit = Loadable(lazy(() => import("../pages/admin/tenants/edit")));
const TenantProductPricesCreate = Loadable(lazy(() => import("../pages/admin/tenants/edit/productPrices/create")));
const TenantProductPricesEdit = Loadable(lazy(() => import("../pages/admin/tenants/edit/productPrices/edit")));
const TenantSignUpInvitationsCreate = Loadable(lazy(() => import("../pages/admin/tenants/invites/invite")));
const Rides = Loadable(lazy(() => import("../pages/rides")));
const RideTemplates = Loadable(lazy(() => import("../pages/ride-templates/list")));
const RideTemplatesCreateOrEdit = Loadable(lazy(() => import("../pages/ride-templates/create-edit")));
const PerDiemAllowances = Loadable(lazy(() => import("../pages/admin/per-diem-allowances/listPerDiems")));
const PerDiemAllowancesEdit = Loadable(lazy(() => import("../pages/admin/per-diem-allowances/editPerDiem")));
const KilometerAllowance = Loadable(lazy(() => import("../pages/admin/kilometer-allowances/list")));
const KilometerAllowanceEdit = Loadable(lazy(() => import("../pages/admin/kilometer-allowances/edit")));
const KilometerAllowanceCreate = Loadable(lazy(() => import("../pages/admin/kilometer-allowances/create")));
const TravelInvoices = Loadable(lazy(() => import("../pages/travel-invoices")));
const TravelInvoicesEdit = Loadable(lazy(() => import("../pages/travel-invoices/edit")));
const AdminDashboard = Loadable(lazy(() => import("../pages/admin/dashboard")));
const StatusPage = Loadable(lazy(() => import("../pages/admin/status")));
const ApprovalProcess = Loadable(lazy(() => import("../pages/admin/approval-process")));
const AddressBook = Loadable(lazy(() => import("../pages/address-book/list/index")));
const AddressBookCreate = Loadable(lazy(() => import("../pages/address-book/create/index")));
const AddressBookEdit = Loadable(lazy(() => import("../pages/address-book/edit/index")));
const Invoices = Loadable(lazy(() => import("../pages/admin/invoice")));
const InvoicesView = Loadable(lazy(() => import("../pages/admin/invoice/view")));
const InvoicesQueue = Loadable(lazy(() => import("../pages/admin/invoice")));
const Dashboard = Loadable(lazy(() => import("../pages/dashboard/index")));
const AccountDimensions = Loadable(lazy(() => import("../pages/account-dimensions/list")));
const AccountDimensionsCreateEdit = Loadable(lazy(() => import("../pages/account-dimensions/create-edit/index")));
const CreateTenant = Loadable(lazy(() => import("../pages/sign-up/tenant")));
const CreateUserFromInvite = Loadable(lazy(() => import("../pages/sign-up/user")));
const SignUpSuccessful = Loadable(lazy(() => import("../pages/sign-up/successful")));
const MealCompensationAllowance = Loadable(lazy(() => import("../pages/admin/meal-compensation-allowances/list")));
const MealCompensationAllowanceEdit = Loadable(lazy(() => import("../pages/admin/meal-compensation-allowances/edit")));
const NightTravelAllowance = Loadable(lazy(() => import("../pages/admin/night-travel-allowances/list")));
const NightTravelAllowanceEdit = Loadable(lazy(() => import("../pages/admin/night-travel-allowances/edit")));

// 404/Error page
const Error = Loadable(lazy(() => import("../pages/404")));
const Unauthorized = Loadable(lazy(() => import("../pages/401")));

export const routes = () => [
    {
        path: "register/customer/:id",
        element:
            <CreateTenant />
    },
    {
        path: "register/customer/success",
        element:
            <SignUpSuccessful />
    },
    {
        path: "register/user/:id",
        element:
            <CreateUserFromInvite />
    },  

    {
        path: "register/user/success",
        element:
            <SignUpSuccessful />
    },
    {
        path: "register",
        element:
            <CreateTenant />
    },
    {
        path: "register/success",
        element:
            <SignUpSuccessful />
    },
    {
        path: "logout",
        element:
            <Logout />
    },
    {
        path: "",
        element: (
            <AuthGuard>
                <LayoutV3 />
            </AuthGuard>
        ),
        children: authRoutes,
    },
    {
        path: "unauthorized",
        element: <Unauthorized />,
    },
    {
        path: "*",
        element: <Error />,
    },
];

export const authRoutes = [
    {path: "/", element: <Dashboard />},
    {
        path: "admin",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <AdminDashboard />
            </PermissionGuard>
    },
    {
        path: "admin/contracts",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <Contracts />
            </PermissionGuard>
    },
    {
        path: "admin/contracts/:id",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <ContractsEdit />
            </PermissionGuard>
    },
    {
        path: "admin/invoices",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <Invoices />
            </PermissionGuard>
    },
    {
        path: "admin/invoices/queue",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <Invoices />
            </PermissionGuard>
    },
    {
        path: "admin/invoices/queue",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <InvoicesQueue />
            </PermissionGuard>
    },
    {
        path: "admin/invoices/:id",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <InvoicesView />
            </PermissionGuard>
    },
    {
        path: "admin/invoices/queue",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <Invoices />
            </PermissionGuard>
    },
    {
        path: "admin/kilometer-allowances",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <KilometerAllowance />
            </PermissionGuard>
    },
    {
        path: "admin/kilometer-allowances/create",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <KilometerAllowanceCreate />
            </PermissionGuard>
    },
    {
        path: "admin/kilometer-allowances/:id",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <KilometerAllowanceEdit />
            </PermissionGuard>
    },
    {
        path: "admin/meal-compensation-allowances",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <MealCompensationAllowance />
            </PermissionGuard>
    },
    {
        path: "admin/meal-compensation-allowances/create",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <MealCompensationAllowanceEdit />
            </PermissionGuard>
    },
    {
        path: "admin/meal-compensation-allowances/:id",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <MealCompensationAllowanceEdit />
            </PermissionGuard>
    },
    {
        path: "admin/night-travel-allowances",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <NightTravelAllowance />
            </PermissionGuard>
    },
    {
        path: "admin/night-travel-allowances/create",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <NightTravelAllowanceEdit />
            </PermissionGuard>
    },
    {
        path: "admin/night-travel-allowances/:id",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <NightTravelAllowanceEdit />
            </PermissionGuard>
    },
    {
        path: "admin/approval-process",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <ApprovalProcess />
            </PermissionGuard>
    },
    {
        path: "admin/payment-terms",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <PaymentTerms />
            </PermissionGuard>
    },
    {
        path: "admin/payment-terms/create",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <PaymentTermsCreate />
            </PermissionGuard>
    },
    {
        path: "admin/payment-terms/:id",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <PaymentTermsEdit />
            </PermissionGuard>
    },
    {
        path: "admin/products",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <Products />
            </PermissionGuard>
    },
    {
        path: "admin/products/create",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <ProductsCreate />
            </PermissionGuard>
    },
    {
        path: "admin/products/:id",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <ProductsEdit />
            </PermissionGuard>
    },
    {
        path: "admin/provisions",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <Provisions />
            </PermissionGuard>
    },
    {
        path: "admin/provisions/history",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <Provisions />
            </PermissionGuard>
    },
    {
        path: "admin/roles",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <Roles />
            </PermissionGuard>
    },
    {
        path: "admin/roles/create",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <RolesCreate />
            </PermissionGuard>
    },
    {
        path: "admin/roles/:id",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <RolesEdit />
            </PermissionGuard>
    },
    {
        path: "admin/sellers",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <Sellers />
            </PermissionGuard>
    },
    {
        path: "admin/sellers/create",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <SellersCreate />
            </PermissionGuard>
    },
    {
        path: "admin/sellers/:id",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <SellersEdit />
            </PermissionGuard>
    },
    {
        path: "admin/sellers/:id/connections",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <SellersEdit />
            </PermissionGuard>
    },
    {
        path: "admin/status",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <StatusPage />
            </PermissionGuard>
    },
    {
        path: "admin/system-settings",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <SystemSettings />
            </PermissionGuard>
    },
    {
        path: "admin/tax-rates",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <TaxRates />
            </PermissionGuard>
    },
    {
        path: "admin/tax-rates/create",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <TaxRatesCreate />
            </PermissionGuard>
    },
    {
        path: "admin/tax-rates/:id",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <TaxRatesEdit />
            </PermissionGuard>
    },
    {
        path: "admin/tax-rates/:id/tax-rate-row",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <TaxRatesRowCreate />
            </PermissionGuard>
    },
    {
        path: "admin/tax-rates/:taxRateId/tax-rate-row/:taxRateRowId",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <TaxRatesRowEdit />
            </PermissionGuard>
    },
    {
        path: "admin/tenants",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <Tenants />
            </PermissionGuard>
    },
    {
        path: "admin/tenants/invites",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <Tenants />
            </PermissionGuard>
    },
    {
        path: "admin/tenants/test-accounts",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <Tenants />
            </PermissionGuard>
    },
    {
        path: "admin/tenants/:tenantId",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <TenantEdit />
            </PermissionGuard>
    },
    {
        path: "admin/tenants/:tenantId/product-pricing",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <TenantEdit />
            </PermissionGuard>
    },
    {
        path: "admin/tenants/:tenantId/product-pricing/create",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <TenantProductPricesCreate />
            </PermissionGuard>
    },
    {
        path: "admin/tenants/:tenantId/product-pricing/:id",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <TenantProductPricesEdit />
            </PermissionGuard>
    },
    {
        path: "admin/tenants/signup-invitation/create",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <TenantSignUpInvitationsCreate />
            </PermissionGuard>
    },
    {
        path: "admin/per-diem-allowances",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <PerDiemAllowances />
            </PermissionGuard>
    },
    {
        path: "admin/per-diem-allowances/:id",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.admin]}>
                <PerDiemAllowancesEdit />
            </PermissionGuard>
    },
    {
        path: "users",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.users.base]}>
                <Users />
            </PermissionGuard>
    },
    {
        path: "users/invites",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.users.base]}>
                <Users />
            </PermissionGuard>
    },
    {
        path: "users/create",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.users.base]}>
                <UserCreate />
            </PermissionGuard>
    },
    {
        path: "users/:id",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.users.base]}>
                <UserEdit />
            </PermissionGuard>
    },
    {
        path: "users/:id/roles",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.users.base]}>
                <UserEdit />
            </PermissionGuard>
    },
    {
        path: "vehicles",
        element:
            <PermissionGuard requiredPermissions={[]}>
                <Vehicles />
            </PermissionGuard>
    },
    {
        path: "vehicles/create",
        element:
            <PermissionGuard requiredPermissions={[]}>
                <VehicleCreate />
            </PermissionGuard>
    },
    {
        path: "vehicles/:id",
        element:
            <PermissionGuard requiredPermissions={[]}>
                <VehicleEdit />
            </PermissionGuard>
    },
    {
        path: "tenant-management",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.tenantManagement]}>
                <TenantManagement />
            </PermissionGuard>
    },
    {
        path: "tenant-management/billing",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.tenantManagement]}>
                <TenantManagement />
            </PermissionGuard>
    },
    {
        path: "tenant-management/settings",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.tenantManagement]}>
                <TenantManagement />
            </PermissionGuard>
    },
    {
        path: "reports/monthly",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.reports.base]}>
                <ReportMonthly />
            </PermissionGuard>
    },
    {
        path: "reports/travel-invoice-report",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.reports.base]}>
                <ReportTravelInvoice />
            </PermissionGuard>
    },
    {
        path: "profile-page",
        element:
            <PermissionGuard requiredPermissions={[]}>
                <ProfilePage />
            </PermissionGuard>
    },
    {
        path: "profile-page/settings",
        element:
            <PermissionGuard requiredPermissions={[]}>
                <ProfilePage />
            </PermissionGuard>
    },
    {
        path: "profile-page/notifications",
        element:
            <PermissionGuard requiredPermissions={[]}>
                <ProfilePage />
            </PermissionGuard>
    },
    {
        path: "rides",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.rides]}>
                <Rides />
            </PermissionGuard>
    },
    {
        path: "rides/recurrence",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.rides]}>
                <Rides />
            </PermissionGuard>
    },    
    {
        path: "rides/recurrence/:id",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.rides]}>
                <RecurringRideEdit />
            </PermissionGuard>
    },
    {
        path: "ride-templates",
        element:
            <PermissionGuard requiredPermissions={[]}>
                <RideTemplates />
            </PermissionGuard>
    },
    {
        path: "ride-templates/create",
        element:
            <PermissionGuard requiredPermissions={[]}>
                <RideTemplatesCreateOrEdit />
            </PermissionGuard>
    },
    {
        path: "ride-templates/:id",
        element:
            <PermissionGuard requiredPermissions={[]}>
                <RideTemplatesCreateOrEdit />
            </PermissionGuard>
    },
    {
        path: "recurring-rides/:id",
        element:
            <PermissionGuard requiredPermissions={[]}>
                <RecurringRideEdit />
            </PermissionGuard>
    },
    {
        path: "travel-invoices",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.travelInvoices.base]}>
                <TravelInvoices />
            </PermissionGuard>
    },
    {
        path: "travel-invoices/approval",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.travelInvoices.approval]}>
                <TravelInvoices />
            </PermissionGuard>
    },
    {
        path: "travel-invoices/repayment",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.travelInvoices.repayment]}>
                <TravelInvoices />
            </PermissionGuard>
    },
    {
        path: "travel-invoice/:id",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.travelInvoices.base]}>
                <TravelInvoicesEdit />
            </PermissionGuard>
    },

    {
        path: "address-book",
        element:
            <PermissionGuard requiredPermissions={[]}>
                <AddressBook />
            </PermissionGuard>
    },

    {
        path: "address-book/create",
        element:
            <PermissionGuard requiredPermissions={[]}>
                <AddressBookCreate />
            </PermissionGuard>
    },

    {
        path: "address-book/:id",
        element:
            <PermissionGuard requiredPermissions={[]}>
                <AddressBookEdit />
            </PermissionGuard>
    },

    {
        path: "account-dimensions",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.accountDimensions]}>
                <AccountDimensions />
            </PermissionGuard>
    },

    {
        path: "account-dimensions/create",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.accountDimensions]}>
                <AccountDimensionsCreateEdit />
            </PermissionGuard>
    },

    {
        path: "account-dimensions/:id",
        element:
            <PermissionGuard requiredPermissions={[PERMISSIONS.accountDimensions]}>
                <AccountDimensionsCreateEdit />
            </PermissionGuard>
    },
];