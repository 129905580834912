import {Box, styled, useTheme} from "@mui/material";
import Scrollbar from "src/components/ScrollBar";
import LayoutDrawer from "src/layouts//layout-parts/LayoutDrawer";
import {FC} from "react";
import {useNavigate} from "react-router-dom";
import MultiLevelMenu from "./MultiLevelMenu";

const TOP_HEADER_AREA = 70;

const NavWrapper = styled(Box)<{ compact: string }>(() => ({
    paddingLeft: 16,
    paddingRight: 16,
    height: "100%",
}));

interface MobileSidebarProps {
    sidebarCompact: boolean;
    showMobileSideBar: boolean;
    toggleMobileSideBar: () => void;
}

const MobileSidebar: FC<MobileSidebarProps> = (props) => {
    const {sidebarCompact, showMobileSideBar, toggleMobileSideBar} = props;
    const theme = useTheme();

    const navigate = useNavigate();
    
    const logoUrl = theme.palette.mode === "dark" ? "/static/logo/logo-transparent-dark.png" : "/static/logo/logo-transparent-white.png";

    return (
        <LayoutDrawer open={showMobileSideBar} onClose={toggleMobileSideBar}>
            <Box pt={2}
                 ml={2}
                 mb={0}
                 pr={2}
                 sx={{cursor: "pointer"}}
                 onClick={() => {
                     toggleMobileSideBar()
                     navigate("/")
                 }}
            >
                <img src={logoUrl} alt="logo" style={{width: "400px", maxWidth:"85%", height: "auto"}} />
            </Box>

            <Scrollbar
                autoHide
                clickOnTrack={false}
                sx={{overflowX: "hidden", maxHeight: `calc(100vh - ${TOP_HEADER_AREA}px)`}}
            >
                <NavWrapper compact={sidebarCompact ? "true" : "false"}>
                    <MultiLevelMenu sidebarCompact={false} onClick={toggleMobileSideBar} />
                </NavWrapper>
            </Scrollbar>
        </LayoutDrawer>
    );
};

export default MobileSidebar;
