import {SvgIcon, SvgIconProps} from "@mui/material";
import React from "react";

const Sessions = (props: SvgIconProps) =>
    (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <path
                d="M20.12,5.12,16.59,1.59A2,2,0,0,0,15.17,1H8.83a2,2,0,0,0-1.42.59L3.88,5.12A3,3,0,0,0,3,7.24v5.85A8,8,0,0,0,6.09,19.4l4.08,3.18A2,2,0,0,0,11.4,23h1.2a2,2,0,0,0,1.23-.42l4.08-3.18A8,8,0,0,0,21,13.09V7.24A3,3,0,0,0,20.12,5.12Zm-1.12,8a5.93,5.93,0,0,1-2.32,4.73L12.6,21H11.4L7.32,17.82A5.93,5.93,0,0,1,5,13.09V7.24a1,1,0,0,1,.29-.7L8.83,3h6.34l3.54,3.54a1,1,0,0,1,.29.7ZM16.08,9.26l-2.6-.2-1-2.43a.5.5,0,0,0-.92,0l-1,2.43-2.6.2a.51.51,0,0,0-.29.88l2,1.7L9,14.38a.5.5,0,0,0,.74.55L12,13.56l2.25,1.37a.5.5,0,0,0,.74-.55l-.61-2.54,2-1.7A.51.51,0,0,0,16.08,9.26Z"
            />
        </SvgIcon>
    );

export default Sessions;
