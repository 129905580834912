import {Badge, Box, IconButton, styled, Typography, useTheme} from "@mui/material";
import FlexBox from "src/components/flexbox/FlexBox";
import {Paragraph} from "src/components/Typography";
import NotificationsIcon from "src/icons/NotificationsIcon";
import {FC, useEffect, useRef, useState} from "react";
import {useSnackbar} from "notistack";
import {useAuth0} from "@auth0/auth0-react";
import {requestHelpers} from "../../../utils/requestHelper";
import {MATKAKULU_API} from "../../../routes/routes-api";
import { NotificationPopoverLayout } from "./NotificationPopoverLayout";

interface NotificationReturnModel {
    notificationItems: NotificationMessage[]

    newNotificationCount: number
}

interface NotificationMessage {
    id: string

    resource: string

    title: string

    timeStamp: string

    seen: boolean
}

const StyledIconButton = styled(IconButton)(({theme}) => ({
    "&:hover": {backgroundColor: theme.palette.action.hover},
}));

export const NotificationsPopover: FC = () => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [notificationsFetched, setNotificationsFetched] = useState(false)
    const {enqueueSnackbar} = useSnackbar()
    const {getAccessTokenSilently} = useAuth0();
    const [notificationItems, setNotificationItems] = useState<NotificationReturnModel>();

    useEffect(() => {
        if (notificationsFetched) return
        setNotificationsFetched(true)

        getNotifications().then(async response => {
            await requestHelpers().handleResponse(response, enqueueSnackbar)

            if (!response.ok) return;
            const data = await response.json() as NotificationReturnModel
            setNotificationItems(data)
        })

        async function getNotifications() {
            const accessToken = await getAccessTokenSilently()
            return fetch(MATKAKULU_API.notification.list,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
        }
    }, [enqueueSnackbar, getAccessTokenSilently, notificationsFetched])

    async function checkAllNotificationsAsRead() {
        if (notificationItems == null || notificationItems.notificationItems == null || notificationItems.notificationItems.length === 0) return;
        const notificationIds = notificationItems.notificationItems.map((item) => item.id);

        const accessToken = await getAccessTokenSilently();
        const response = await fetch(MATKAKULU_API.notification.markAsSeen, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({notificationIds}),
        });

        await requestHelpers().handleResponse(response, enqueueSnackbar);

        setNotificationsFetched(false)
    }

    async function checkNotificationAsRead(notificationId: string) {
        if (notificationItems == null || notificationItems.notificationItems == null || notificationItems.notificationItems.length === 0) return;

        const accessToken = await getAccessTokenSilently();
        const response = await fetch(MATKAKULU_API.notification.markAsSeen, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({notificationIds: [notificationId]}),
        });

        await requestHelpers().handleResponse(response, enqueueSnackbar);

        setNotificationsFetched(false)
    }

    return (
        <>
            <StyledIconButton sx={{mr: 0.5}} ref={anchorRef} onClick={() => setOpen(true)}>
                <Badge color="primary" badgeContent={notificationItems?.newNotificationCount} max={10}>
                    <NotificationsIcon />
                </Badge>
            </StyledIconButton>

            <NotificationPopoverLayout
                title="Ilmoitukset"
                popoverOpen={open}
                anchorRef={anchorRef}
                popoverClose={() => setOpen(false)}
                checkAllNotificationsAsSeen={() => checkAllNotificationsAsRead()}
                newNotificationCount={notificationItems?.newNotificationCount}
            >
                {notificationItems?.notificationItems.length === 0 ? (
                    <Paragraph fontWeight="500" textAlign="center" p={2}>
                        Ei uusia ilmoituksia
                    </Paragraph>
                ) : (
                    <>
                        {notificationItems?.notificationItems.map((item: NotificationMessage) => (
                            <ListItem onClick={() => checkNotificationAsRead(item.id)} resource={item.resource}
                                      timeStamp={item.timeStamp} seen={item.seen} key={item.id} title={item.title}
                            />
                        ))}
                    </>
                )}
            </NotificationPopoverLayout>
        </>
    );
};


function ListItem({resource, timeStamp, seen, title, onClick}: {
    resource: string,
    timeStamp: string,
    seen: boolean,
    title: string,
    onClick: () => void
}) {
    const theme = useTheme();
    const colorbg =
        theme.palette.mode === "light" ? "secondary.light" : "text.disabled";

    return (
        <FlexBox
            p={1}
            onClick={onClick}
            alignItems="center"
            sx={{
                borderBottom: 1,
                cursor: "pointer",
                borderColor: "divider",
                backgroundColor: "transparent",
                "&:hover": {backgroundColor: colorbg},
            }}
        >
            <FlexBox alignItems="center">
                <Box
                    sx={{
                        ml: 2,
                        width: 8,
                        height: 8,
                        marginRight: 0,
                        borderRadius: "50%",
                        backgroundColor:
                            seen ? "primary.light" : "primary.main",
                    }}
                />
            </FlexBox>

            {/* <Typography sx={{fontSize: 12, fontWeight: 300}} color="text.disabled">{timeStamp}</Typography> */}
            <Box sx={{ml:2}}>
                <Box>
                    <Typography sx={{fontSize: 12, fontWeight: 500}}>
                        {title}
                    </Typography>
                </Box>
                <Box>
                    <Typography sx={{fontSize: 12, fontWeight: 500, whitespace:"nowrap"}}>
                        {resource}
                    </Typography>
                </Box>
            </Box>
        </FlexBox>
    )
}