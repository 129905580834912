import i18next from "i18next";
import {initReactI18next} from "react-i18next";

export const resources = {
    fi: {
        translation: {
            general: {
                validation: {
                    required_field: "Pakollinen tieto",
                    invalid_email: "Virheellinen sähköposti",
                    min_length: "Vähimmäispituus {0} merkkiä",
                    max_length: "Enimmäispituus {0} merkkiä",
                    min_value: "Vähimmäismäärä on {0}",
                    max_value: "Enimmäismäärä on {0}",
                },
                create: "Lisää",
                save: "Tallenna",
                cancel: "Peruuta",
                saveDraft: "Tallenna luonnoksena",
            },
            seller: {
                firstName: "Etunimi",
                commissionCalculationValidForMonths: "Voimassaoloaika (kk)",
                lastName: "Sukunimi",
                recurrenceType: "Toistumistyyppi",
                commissionType: "Provisiotyyppi",
                commissionValue: "Provisio"

            },
            signUp: {
                acceptTos: "Käyttöehdot on hyväksyttävä"
            },
            attachment: {
                missingAttachment: "Lisää liite"
            }
        },
    },
    en: {
        translation: {
            general: {
                validation: {
                    required_field: "Required field",
                    invalid_email: "Invalid email",
                },
                save: "Save",
                cancel: "Cancel",
                saveDraft: "Save as draft",
            },
            seller: {
                firstName: "First name",
                commissionCalculationValidForMonths: "Valid for months",
                lastName: "Last name",
                recurrenceType: "Recurrence type",
                commissionType: "Commission type",
                commissionValue: "Commission"
            },
        },
    }
};

i18next.use(initReactI18next).init({
    resources,
    lng: "fi",
    fallbackLng: "fi",
    interpolation: {escapeValue: false},
});
