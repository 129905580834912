import {SvgIconProps} from "@mui/material/SvgIcon";
import car from "../../icons/Car";
import peopleIcon from "../../icons/PeopleIcon";
import DollarOutlined from "../../icons/DollarOutlined";
import sidebar from "../../icons/sidebar";
import groupSenior from "../../icons/GroupSenior";
import Bratislava from "../../icons/Bratislava";
import PencilIcon from "../../icons/PencilIcon";
import invoice from "../../icons/sidebar/Invoice";
import settingsOutlined from "../../icons/SettingsOutlined";
import account from "../../icons/account";
import fileIcon from "../../icons/FileIcon";
import {PERMISSIONS} from "../../resources/permissions";
import mapMarkerIcon from "../../icons/MapMarkerIcon";
import user from "../../icons/User";
import HomeOutlined from "../../icons/HomeOutlined";
import toggleIcon from "../../icons/ToggleIcon";
import csvFileIcon from "../../icons/CsvFileIcon";
import moneyIcon from "../../icons/MoneyIcon";
import settingIcon from "../../icons/SettingIcon";
import Luck from "../../icons/Luck";
import MedalIcon from "../../icons/MedalIcon";
import Premium from "../../icons/Premium";

export class NavigationItem {
    name: string

    icon?: (props: SvgIconProps) => JSX.Element

    path?: string

    children?: NavigationItem[]

    requiredPermissions: string[]

    constructor(name: string, requiredPermissions: string[], icon?: (props: SvgIconProps) => JSX.Element, path?: string, children?: NavigationItem[]) {
        this.name = name;
        this.icon = icon;
        this.path = path;
        this.children = children;
        this.requiredPermissions = requiredPermissions;
    }
}

export function getNavigation() {
    const navigations: NavigationItem[] = [];

    navigations.push(new NavigationItem('Matkalaskut', [PERMISSIONS.travelInvoices.base], invoice, '/travel-invoices'));
    navigations.push(new NavigationItem('Ajomatkat', [PERMISSIONS.rides], car, '/rides'));

    navigations.push(
        new NavigationItem('Raportit', [PERMISSIONS.reports.base], fileIcon, undefined,
            [
                new NavigationItem('Kuukausiraportti', [PERMISSIONS.reports.base], undefined, '/reports/monthly'),
                new NavigationItem('Matkalaskuraportti', [PERMISSIONS.reports.base], undefined, '/reports/travel-invoice-report'),
            ]
        )
    );

    navigations.push(new NavigationItem(
            // Permissions added to top level to hide menu from accountant
            'Asetukset', [PERMISSIONS.travelInvoices.base], settingsOutlined, undefined,
            [
                new NavigationItem('Ajoneuvot', [], car, '/vehicles'),
                new NavigationItem('Asiakastili', [PERMISSIONS.tenantManagement], user, '/tenant-management'),
                new NavigationItem('Kustannuspaikat', [PERMISSIONS.accountDimensions], HomeOutlined, '/account-dimensions'),
                new NavigationItem('Käyttäjät', [PERMISSIONS.users.base], peopleIcon, '/users'),
                // new NavigationItem('Osoitekirja', [], toggleIcon, '/address-book'),
                new NavigationItem('Reittipohjat', [], mapMarkerIcon, '/ride-templates')
            ]
        )
    );

    navigations.push(new NavigationItem(
            'Admin', [PERMISSIONS.admin], account.LockOutlined, undefined,
            [
                new NavigationItem('Alvluokat', [PERMISSIONS.admin], Luck, '/admin/tax-rates'),
                new NavigationItem('Asiakkaat', [PERMISSIONS.admin], Bratislava, '/admin/tenants'),
                new NavigationItem('Ateriakorvaussäännöt', [PERMISSIONS.admin], MedalIcon, '/admin/meal-compensation-allowances'),
                new NavigationItem('Dashboard', [PERMISSIONS.admin], sidebar.DashboardIcon, '/admin'),
                new NavigationItem('Hyväksyntäprosessi', [PERMISSIONS.admin], settingIcon, '/admin/approval-process'),
                new NavigationItem('Kilometrikorvaukset', [PERMISSIONS.admin], PencilIcon, '/admin/kilometer-allowances'),
                new NavigationItem('Järjestelmäasetukset', [PERMISSIONS.admin], sidebar.AccountSettingsIcon, '/admin/system-settings'),
                new NavigationItem('Laskutus', [PERMISSIONS.admin], Premium, '/admin/invoices'),
                new NavigationItem('Maksuehdot', [PERMISSIONS.admin], sidebar.UserManagementIcon, '/admin/payment-terms'),
                new NavigationItem('Myyjät', [PERMISSIONS.admin], groupSenior, '/admin/sellers'),
                new NavigationItem('Provisiot', [PERMISSIONS.admin], moneyIcon, '/admin/provisions'),
                new NavigationItem('Päivärahat', [PERMISSIONS.admin], DollarOutlined, '/admin/per-diem-allowances'),
                new NavigationItem('Roolit', [PERMISSIONS.admin], groupSenior, '/admin/roles'),
                new NavigationItem('Sopimukset', [PERMISSIONS.admin], csvFileIcon, '/admin/contracts'),
                new NavigationItem('Status', [PERMISSIONS.admin], sidebar.UserManagementIcon, '/admin/status'),
                new NavigationItem('Tuotteet', [PERMISSIONS.admin], sidebar.EcommerceIcon, '/admin/products'),
                new NavigationItem('Yömatkarahat', [PERMISSIONS.admin], sidebar.ChartDonut, '/admin/night-travel-allowances'),
            ]
        )
    );

    return navigations;
}