import {OptionsObject, SnackbarMessage, VariantType} from "notistack";

interface NotificationMessage {
    message: string;
    variant: VariantType;
    autoHideDurationMs: number;
}

interface ResponseModel {
    notifications: NotificationMessage[]
}

export function requestHelpers() {
    function handleNotifications(responseModel: ResponseModel, enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => void) {
        if (responseModel.notifications === undefined) return;

        for (let i = 0; i < responseModel.notifications.length; i++) {
            const notification = responseModel.notifications[i];
            
            enqueueSnackbar(
                notification.message,
                {
                    variant: notification.variant,
                    autoHideDuration: notification.autoHideDurationMs
                }
            );
        }
    }

    return {
        async handleResponse(response: Response, enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => void) {
            const clone = response.clone();
            
            const responseModel = await clone.json();
            // TODO KK: try catch käsittely tälle, jos tulee ok result ja ei o contenttia ni json hajottaa
            if (responseModel == null) return;

            if (clone.ok) {
                handleNotifications(responseModel, enqueueSnackbar);
            } else {
                enqueueSnackbar(responseModel.message, {variant: "error", autoHideDuration: 10000});
            }
        }
    }
}