import {Box, Card, useTheme,} from "@mui/material";
import FlexRowAlign from "src/components/flexbox/FlexRowAlign";
import {FC} from "react";
import {useAuth0} from "@auth0/auth0-react";
import LoadingScreen from "../../components/LoadingScreen";

const Login: FC = () => {
    const {loginWithRedirect, isAuthenticated, isLoading} = useAuth0();
    const theme = useTheme();

    if (isLoading) {
        return (
            <LoadingScreen />
        )
    }

    if (!isLoading && !isAuthenticated) {
        loginWithRedirect();
    }
    // TODO KK: asiakastilin pickkeriin footeriin napit, nyt rullautuu alas asti tyhmästi
    // TODO KK: Teksti ei oikein toimi järkevästi

    const logoUrl = theme.palette.mode === "dark" ? "/static/logo/logo-transparent-dark.png" : "/static/logo/logo-transparent-white.png";

    return (
        <FlexRowAlign flexDirection="column" sx={{height: {sm: "100%"}}}>
            <Card sx={{padding: 4, maxWidth: 600, boxShadow: 1}}>
                <FlexRowAlign flexDirection="column">
                    <Box>
                        <img src={logoUrl} width="100%" alt="" />
                    </Box>
                    {/* <H1 fontSize={24} fontWeight={700}> */}
                    {/*    Kirjaudutaan sisään... */}
                    {/* </H1> */}
                </FlexRowAlign>
            </Card>
        </FlexRowAlign>
    );
};

export default Login;
