import {ReactNode} from "react";
import {Navigate, useLocation} from "react-router-dom";
import useSessionUser from "../../resources/hooks/useSessionUser";
import {PermissionError} from "./PermissionError";
import {SessionUser} from "../../resources/SessionUser";
import LoadingScreen from "../../components/LoadingScreen";

function hasPermission(requiredPermissions: string[], sessionUser: SessionUser | undefined) {
    if (sessionUser == null) {
        return false;
    }

    if (requiredPermissions.length === 0) {
        return true;
    }
    
    let hasAllRequiredPermissions = true;
    requiredPermissions.forEach(requiredPermission => {
        if (!sessionUser.permissions.includes(requiredPermission)) {
            hasAllRequiredPermissions = false;
        }
    })

    return hasAllRequiredPermissions;
}

export function PermissionGuard(
    {
        requiredPermissions,
        children
    }: {
        requiredPermissions?: string[],
        children: ReactNode
    }
) {
    const {sessionUser, isLoading} = useSessionUser();
    const location = useLocation();

    if (requiredPermissions == null) {
        return <PermissionError />
    }

    if (isLoading) {
        return <LoadingScreen />
    }

    if (requiredPermissions && hasPermission(requiredPermissions, sessionUser)) {
        return <> {children} </>
    }

    if (process.env.NODE_ENV === "development") {
        console.log(`PermissionGuard: \r\nUser does not have required permissions for path "${location.pathname}"\r\n`, requiredPermissions);
    }

    return (
        <Navigate to="/unauthorized" replace />
    )
}