import AccountSettingsIcon from "./AccountSettings";
import AdminEcommerceIcon from "./AdminEcommerce";
import CalendarIcon from "./Calendar";
import ChartDonut from "./ChartDonutIcon";
import ChatIcon from "./Chat";
import DashboardIcon from "./Dashboard";
import DataTableIcon from "./DataTable";
import EcommerceIcon from "./Ecommerce";
import InvoiceIcon from "./Invoice";
import KanbanIcon from "./Kanban";
import PagesIcon from "./Pages";
import ProjectIcon from "./Projects";
import SessionsIcon from "./Sessions";
import UserManagementIcon from "./UserManagement";
import UserProfileIcon from "./UserProfile";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ChatIcon,
    PagesIcon,
    ChartDonut,
    KanbanIcon,
    ProjectIcon,
    InvoiceIcon,
    SessionsIcon,
    CalendarIcon,
    DashboardIcon,
    DataTableIcon,
    EcommerceIcon,
    UserProfileIcon,
    AdminEcommerceIcon,
    UserManagementIcon,
    AccountSettingsIcon,
};
