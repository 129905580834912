import React from "react";
import ReactDOM from "react-dom/client";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import SettingsProvider from "src/contexts//settingsContext";
import "nprogress/nprogress.css";
import "react-quill/dist/quill.snow.css";
import "simplebar-react/dist/simplebar.min.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import {Auth0Provider} from "@auth0/auth0-react";
import {SnackbarProvider} from "notistack";
import fiFI from 'date-fns/locale/fi';
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fiFI}>
            <SettingsProvider>
                <SnackbarProvider>
                    <Auth0Provider
                        domain={process.env.REACT_APP_DOMAIN as string}
                        clientId={process.env.REACT_APP_CLIENT_ID as string}
                        // useRefreshTokens={true}
                        // useRefreshTokensFallback={false}
                        authorizationParams={{
                            redirect_uri: window.location.origin,
                            audience: process.env.REACT_APP_AUDIENCE,
                            ui_locales: "fi"
                        }}
                    >
                        <App />
                    </Auth0Provider>
                </SnackbarProvider>
            </SettingsProvider>
        </LocalizationProvider>
    </React.StrictMode>
);  