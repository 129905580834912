import {useAuth0} from "@auth0/auth0-react";
import {Box, Grid, Paper} from "@mui/material";
import {useSnackbar} from "notistack";
import {CSSProperties, useEffect, useState} from "react";
import {MATKAKULU_API} from "../../routes/routes-api";
import {requestHelpers} from "../../utils/requestHelper";
import LayoutBodyWrapper from "../../layouts/layout-parts/LayoutBodyWrapper";
import useSessionUser from "../../resources/hooks/useSessionUser";

interface Tenant {
    value: string;
    name: string;
}
 
export function TenantSelector() {
    const {getAccessTokenSilently} = useAuth0()
    const {enqueueSnackbar} = useSnackbar()

    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [dataFetched, setDataFetched] = useState(false);

    const {clearSessionUser} = useSessionUser();

    useEffect(() => {
        const fetchData = async () => {
            if (dataFetched) return
            setDataFetched(true)

            const accessToken = await getAccessTokenSilently()
            const response = await fetch(MATKAKULU_API.user.getUserTenants, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                }
            })
                await requestHelpers().handleResponse(response, enqueueSnackbar);

                if (response.ok) {
                    const data = await response.json()
                    setTenants(data.tenants)
                }
        }

        // noinspection JSIgnoredPromiseFromCall
        fetchData()
    }, [dataFetched, enqueueSnackbar, getAccessTokenSilently])

    async function switchTenant(tenantId: string) {
        const accessToken = await getAccessTokenSilently()
        const response = await fetch(MATKAKULU_API.user.switchTenant, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({tenantId}),
        })
            if (response.ok) {
                // Forces session user to be fetched again to update permissions etc
                clearSessionUser();

                window.location.reload();
            }
    }

    // dashboard body wrapper custom style
    const customStyle: CSSProperties = {
        width: `calc(100% )`,
        marginLeft: "auto",
        marginRight: "auto",
    };
// TODO KK: Nätimpi tyyli
    return (
        <LayoutBodyWrapper sx={customStyle}>
            <Box textAlign="center" sx={{mb: 2}}>
                Valitse asiakastili
            </Box>
            <Grid container spacing={2}>
                {
                    tenants.map((tenant) => (
                        <Grid item xs={12} key={tenant.value}>
                            <Paper sx={{p: 2, textAlign: "left", cursor: "pointer"}}
                                   onClick={() => switchTenant(tenant.value)}
                            >
                                {tenant.name}
                            </Paper>
                        </Grid>
                    ))
                }
            </Grid>
        </LayoutBodyWrapper>

    )
}