import {SvgIcon, SvgIconProps} from "@mui/material";

const Pages = (props: SvgIconProps) =>
    (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <path
                d="M15,5V19H5V5H15m0-2H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H15a2,2,0,0,0,2-2V5a2,2,0,0,0-2-2Zm6,3V18a1,1,0,0,1-1,1H19V5h1A1,1,0,0,1,21,6Z"
            />
        </SvgIcon>
    );

export default Pages;
