import {useAuth0} from '@auth0/auth0-react';
import {useEffect, useState} from 'react';
import {SessionUser, SessionUserModel} from "../SessionUser";
import {MATKAKULU_API} from "../../routes/routes-api";

export default function useSessionUser(): SessionUserModel {
    const [getLoginData, setGetLoginData] = useState(true)
    const [sessionUser, setSessionUser] = useState<SessionUser>(SessionUser.Create());
    const {getAccessTokenSilently, isAuthenticated} = useAuth0();
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => { 
        if (!isAuthenticated || !getLoginData) return;
        setGetLoginData(false);

        (async () => {
            const sessionUserStorageObj = localStorage.getItem("SESSION_USER")

            if (sessionUserStorageObj != null) {
                const {
                    userFirstname,
                    userLastname,
                    userEmail,
                    validUntil,
                    permissions,
                    redirectToTenantSelector,
                    showTenantSwitchButton,
                    userId
                } = JSON.parse(sessionUserStorageObj) as SessionUser

                if (validUntil != null && new Date() < new Date(validUntil)) {
                    setSessionUser(new SessionUser(userFirstname, userLastname, userEmail, new Date(validUntil), permissions, redirectToTenantSelector, showTenantSwitchButton, userId))
                    setIsLoading(false)
                    return
                }
            }

            const accessToken = await getAccessTokenSilently()
            const response = await fetch(MATKAKULU_API.login, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                }
            });

            const {
                userFirstname,
                userLastname,
                userEmail,
                validUntil,
                permissions,
                redirectToTenantSelector,
                showTenantSwitchButton,
                userId
            } = await response.json() as SessionUserResponse
            const validUntilDate = new Date(validUntil)

            localStorage.setItem("SESSION_USER", JSON.stringify(new SessionUser(userFirstname, userLastname, userEmail, validUntilDate, permissions, redirectToTenantSelector, showTenantSwitchButton, userId)))
            setSessionUser(new SessionUser(userFirstname, userLastname, userEmail, validUntilDate, permissions, redirectToTenantSelector, showTenantSwitchButton, userId))

            setIsLoading(false)
        })()
    }, [getAccessTokenSilently, getLoginData, sessionUser, isAuthenticated]);

    return new SessionUserModel(sessionUser, isLoading);
}

export interface SessionUserResponse {
    userFirstname: string

    userLastname: string

    userEmail: string

    isAdmin: boolean

    validUntil: string

    permissions: string[]

    redirectToTenantSelector: boolean

    showTenantSwitchButton: boolean
    
    userId: string
}
